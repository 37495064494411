import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import type { DrawerProps } from '@mui/material';
import { Drawer, List, ListItem, ListItemIcon, Toolbar } from '@mui/material';
import { useRouter } from 'next/router';

import type { FC, ReactNode } from 'react';
import { useCallback, useMemo } from 'react';

import type { Consultant } from '~/api';

interface SideMenuListitemProps {
  url: string;
  openNewWindow?: boolean;
  children: ReactNode;
}

const SideMenuListItem = (props: SideMenuListitemProps) => {
  const { url, openNewWindow = false, children } = props;

  const router = useRouter();

  const onClick = useCallback(async () => {
    // 現在と同じパスに遷移すると、/dashboardや/shadowing-correctionで
    // クエリパラメータが消えてしまうため、同じパスには遷移しない
    if (router.pathname !== url) {
      if (openNewWindow) window.open(url);
      else router.push(url);
    }
  }, [openNewWindow, router, url]);

  return (
    <ListItem
      button
      divider
      onClick={onClick}
      className="p-4 hover:bg-orange-40"
      component="li"
    >
      {children}
    </ListItem>
  );
};

const SideMenuListItemIcon: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return <ListItemIcon className="min-w-0 text-white">{children}</ListItemIcon>;
};

export const DrawerMenu = (props: DrawerProps & { user: Consultant }) => {
  const isDedicatedShadowingCorrectionUser = useMemo(() => {
    return props.user.school_building_id == null;
  }, [props.user]);
  return (
    <Drawer {...props}>
      <Toolbar />
      <div className="overflow-auto">
        <List data-testid="side-menu">
          {!isDedicatedShadowingCorrectionUser && (
            <SideMenuListItem url="/dashboard">
              <SideMenuListItemIcon>
                <HomeOutlinedIcon />
              </SideMenuListItemIcon>
            </SideMenuListItem>
          )}
          <SideMenuListItem url="/shadowing-correction">
            <SideMenuListItemIcon>
              <MessageOutlinedIcon />
            </SideMenuListItemIcon>
          </SideMenuListItem>
          {!isDedicatedShadowingCorrectionUser && (
            <SideMenuListItem
              url="https://sites.google.com/progrit.co.jp/training-method/%E3%83%9B%E3%83%BC%E3%83%A0"
              openNewWindow
            >
              <SideMenuListItemIcon>
                <FolderOpenOutlinedIcon />
              </SideMenuListItemIcon>
            </SideMenuListItem>
          )}
          <SideMenuListItem url="/setting">
            <SideMenuListItemIcon>
              <SettingsOutlinedIcon />
            </SideMenuListItemIcon>
          </SideMenuListItem>
          {!isDedicatedShadowingCorrectionUser && (
            <SideMenuListItem
              url="https://progrit-product.zendesk.com"
              openNewWindow
            >
              <SideMenuListItemIcon>
                <HelpOutlineIcon />
              </SideMenuListItemIcon>
            </SideMenuListItem>
          )}
        </List>
      </div>
    </Drawer>
  );
};
