import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';

import { useMemo, useState, useCallback } from 'react';

import type { Consultant } from '~/api';
import { api } from '~/api';
import { useAspidaSWR } from '~/hooks/useAspidaSWR';
import { StatusError } from '~/shared/Errors';

export const publicPaths: ReadonlyArray<string> = [
  '/signin',
  '/initial-login',
  '/reset-password',
  '/forgot-password',
  '/initial-login-password',
  '/send-reset-password-mail/done',
] as const;

const getToken = () => {
  if (typeof window != 'undefined') {
    return window.localStorage.getItem('jwt');
  }
  return null;
};
const removeToken = () => {
  if (typeof window != 'undefined') {
    window.localStorage.removeItem('jwt');
  }
};

const shadowingCorrectionUserAllowedPaths: ReadonlyArray<string> = [
  ...publicPaths,
  '/shadowing-correction',
  '/shadowing-correction/[shadowing_correction_id]',
  '/setting',
  '/404',
] as const;

const hasPermission = (consultantsMe: Consultant, path: string) => {
  // school_building_idがない場合はシャドーイング添削の添削ユーザーとみなし、限られたパスのみ許可
  const isShadowingUser = consultantsMe.school_building_id == null;
  if (isShadowingUser)
    return shadowingCorrectionUserAllowedPaths.includes(path);

  return true;
};

export const useAuth = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { data, error, ...other } = useAspidaSWR(api.v1.consultants.me, 'get', {
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateOnReconnect: false,
  });

  const user = useMemo(() => {
    setIsLoading(!data?.data && getToken() != null);
    return data?.data;
  }, [data]);

  const logout = useCallback(async () => {
    try {
      await api.v1.auth.logout.post();
    } finally {
      removeToken();
      location.href = '/signin'; // TODO: 値の初期化をケアした上でSPA的な遷移にする
    }
  }, []);

  return {
    isLoading,
    user,
    logout,
    error,
    ...other,
  };
};

export const useAuthMiddleware = () => {
  const router = useRouter();
  const { user, isLoading, error } = useAuth();
  const [statusError, setStatusError] = useState<StatusError | undefined>();

  const isValidating = useMemo(() => {
    if (user && !error) {
      Sentry.setUser({
        id: user.id,
        username: user.sf_consultant_name,
        email: user.sf_email,
      });
    }

    // NOTE: Permissionエラーから他ページにNextLinkでルーティングした時に一度リセットしないとコンテンツ表示されない
    setStatusError(undefined);
    const path = router.pathname;
    // ログイン済みでルートかサインインページの場合はログイン後の画面にリダイレクトする
    if (['/', '/signin'].includes(path)) {
      if (isLoading) return true;
      else if (user) {
        const isShadowingUser = user.school_building_id == null;
        router.replace(
          isShadowingUser ? '/shadowing-correction' : '/dashboard'
        );
        return true; // NOTE: リダイレクトされるまでログイン画面が出るので isValidatingはtrueとする
      }
    }
    if (publicPaths.includes(path)) return false; // publicページは何もチェックしない
    if (!user && isLoading) return true; // コンサルデータが無く、認証トークンがある場合はログイン情報取得中として何もしない
    if (!user || error) {
      // ログイン情報が取得できないときはログイン画面にリダイレクトする
      if (typeof window != 'undefined') {
        location.replace('/signin'); // TODO: 値の初期化をケアした上でSPA的な遷移にする
      }
      return false;
    }

    if (!hasPermission(user, path)) {
      Sentry.captureMessage('Permission Denied', 'warning');
      setStatusError(
        new StatusError({
          path,
          message: 'Permission Denied',
          statusCode: 403,
        })
      );
    }

    return false;
  }, [user, router, error, isLoading]);

  return {
    isValidating,
    statusError,
  };
};
