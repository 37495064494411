import type { AspidaParams, AspidaResponse, LowerHttpMethod } from 'aspida';
import type { AxiosError, AxiosRequestConfig } from 'axios';
import { useMemo } from 'react';

import type { SWRConfiguration, SWRResponse } from 'swr';
import useSWR from 'swr';

type ResponseData<
  T extends (option?: AspidaParams) => Promise<AspidaResponse>
> = ReturnType<T> extends Promise<AspidaResponse<infer S>> ? S : never;
type AspidaRequestOption = {
  query?: Record<string, string | number | undefined>;
  /**
   * @default false
   */
  disableCache?: boolean;
  config?: AxiosRequestConfig;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface AspidaClientObject extends Record<string, any> {
  $path: () => string;
}

export type Option = SWRConfiguration & AspidaRequestOption;

export function useAspidaSWR<
  T extends AspidaClientObject,
  U extends Extract<keyof T, LowerHttpMethod>
>(
  api: T | undefined | null,
  method: U,
  option?: Option
): SWRResponse<ResponseData<T[U]>, AxiosError> {
  const path = useMemo(() => {
    const path = api ? api.$path() : null;
    if (path == null) return null;
    if (typeof window === 'undefined') return path;
    const url = new URL(path, window.location.origin);
    if (option?.query) {
      for (const [key, value] of Object.entries(option.query)) {
        if (value == null) continue;
        url.searchParams.append(key, `${value}`);
      }
    }

    return option?.disableCache === true
      ? [`${path}${url.search}`, Date.now()]
      : `${path}${url.search}`;
  }, [api, option?.disableCache, option?.query]);

  const fetcher = useMemo(() => {
    if (api == null) return null;
    return async () => {
      const res = await api[method](option);
      return res.body;
    };
  }, [api, method, option]);

  return useSWR(path, fetcher, option);
}
