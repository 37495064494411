import { detect } from 'detect-browser';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';

import { Error } from '~/components/Error';

export interface RestrictBrowserMiddlewareProps {
  children?: ReactNode;
}

export const RestrictBrowserMiddleware = ({
  children,
}: RestrictBrowserMiddlewareProps) => {
  const [isNonChromeBrowser, setIsNonChromeBrowser] = useState(false);
  useEffect(() => {
    setIsNonChromeBrowser(detect()?.name !== 'chrome');
  }, []);

  if (isNonChromeBrowser)
    return (
      <Error
        title="Google Chromeをご利用ください。"
        body="ご使用のブラウザでは正常に動作しません。"
      />
    );

  return <>{children}</>;
};
