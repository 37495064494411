import {
  AppBar,
  Avatar,
  Button,
  Divider,
  Grid,
  Menu,
  MenuItem,
} from '@mui/material';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useMemo, useRef, useState, useCallback } from 'react';

import { DrawerMenu } from './DrawerMenu';

import type { Consultant } from '~/api';
import { publicPaths, useAuth } from '~/hooks/useAuth';

const UserDropdown: React.FC<{ user: Consultant }> = ({ user }) => {
  const { logout } = useAuth();
  const imageUrl = useMemo(() => {
    if (user?.consultant_icon_url == null) return;
    return `${process.env.IMG_BASE}/${user?.consultant_icon_url}`;
  }, [user?.consultant_icon_url]);

  const anchorRef = useRef(null); // Menuのアンカー用のRef
  const [open, setOpen] = useState(false);

  const router = useRouter();

  const onUserSettingClick = async () => {
    await router.push('/setting');
    setOpen(false);
  };

  const onLogoutClick = useCallback(async () => {
    try {
      await logout();
    } finally {
      setOpen(false);
    }
  }, [logout]);

  return (
    <div data-testid="header-user-icon">
      <Button onClick={() => setOpen(true)} ref={anchorRef}>
        <Avatar src={imageUrl} className="mr-4 h-8 w-8" />
        <div>{user.sf_consultant_name}</div>
        <Divider orientation="vertical" flexItem className="mx-4" />
        <div>{user.school_building?.school_building_name}</div>
      </Button>

      <Menu
        anchorEl={anchorRef.current}
        open={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        classes={{ paper: 'w-[200px] mt-8' }}
      >
        <MenuItem onClick={onUserSettingClick} divider>
          ユーザー設定
        </MenuItem>
        <MenuItem data-testid="logout-link" onClick={onLogoutClick}>
          ログアウト
        </MenuItem>
      </Menu>
    </div>
  );
};

export const SimpleAppBar: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <>
      <AppBar
        color="secondary"
        position="fixed"
        className="place-content-center p-2"
        sx={(theme) => ({ zIndex: theme.zIndex.drawer + 1 })}
        data-testid="header"
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Image src="/images/logo.svg" alt="Logo" height={32} width={144} />
          {children}
        </Grid>
      </AppBar>
    </>
  );
};

export const AuthAppBar: React.FC = () => {
  const { user } = useAuth();
  return (
    <>
      <SimpleAppBar>{user && <UserDropdown user={user} />}</SimpleAppBar>
      {user && <DrawerMenu variant="permanent" user={user} />}
    </>
  );
};

export const MyAppBar: React.FC = () => {
  const { pathname } = useRouter();
  const isPublic = useMemo(() => publicPaths.includes(pathname), [pathname]);
  return isPublic ? <SimpleAppBar /> : <AuthAppBar />;
};
