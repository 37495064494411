import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Grid, Typography } from '@mui/material';

export interface ErrorViewProps {
  title: React.ReactNode;
  body?: React.ReactNode;
}

export const Error = ({ title, body }: ErrorViewProps) => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      sx={(theme) => ({
        height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
      })}
    >
      <ErrorOutlineIcon className="text-[7rem] text-gray-60" />
      <Typography variant="h1" className="py-2 text-center text-gray-110">
        {title}
      </Typography>
      {body != null && (
        <Typography variant="body1" className="py-2 text-center text-gray-110">
          {body}
        </Typography>
      )}
    </Grid>
  );
};
