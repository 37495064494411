/* eslint-disable */
// prettier-ignore
import { AspidaClient, BasicHeaders, dataToURLString } from 'aspida'
// prettier-ignore
import { Methods as Methods0 } from './v1/auth/apply'
// prettier-ignore
import { Methods as Methods1 } from './v1/auth/login'
// prettier-ignore
import { Methods as Methods2 } from './v1/auth/logout'
// prettier-ignore
import { Methods as Methods3 } from './v1/auth/password_reset/reset'
// prettier-ignore
import { Methods as Methods4 } from './v1/auth/password_reset/tokens'
// prettier-ignore
import { Methods as Methods5 } from './v1/auth/password_reset/verify'
// prettier-ignore
import { Methods as Methods6 } from './v1/auth/refresh'
// prettier-ignore
import { Methods as Methods7 } from './v1/auth/register'
// prettier-ignore
import { Methods as Methods8 } from './v1/auth/verify'
// prettier-ignore
import { Methods as Methods9 } from './v1/consultants'
// prettier-ignore
import { Methods as Methods10 } from './v1/consultants/me'
// prettier-ignore
import { Methods as Methods11 } from './v1/consultants/me/icon'
// prettier-ignore
import { Methods as Methods12 } from './v1/consultants/me/password'
// prettier-ignore
import { Methods as Methods13 } from './v1/consultants/me/school_building'
// prettier-ignore
import { Methods as Methods14 } from './v1/course_type_ids'
// prettier-ignore
import { Methods as Methods15 } from './v1/data/account_refresh/sync'
// prettier-ignore
import { Methods as Methods16 } from './v1/data/shadowing_lock/sync'
// prettier-ignore
import { Methods as Methods17 } from './v1/data/student_shadowing_item_id/sync'
// prettier-ignore
import { Methods as Methods18 } from './v1/data/study_master/sync'
// prettier-ignore
import { Methods as Methods19 } from './v1/incharge_students'
// prettier-ignore
import { Methods as Methods20 } from './v1/school_buildings'
// prettier-ignore
import { Methods as Methods21 } from './v1/shadowing_corrections/_shadowing_correction_id@number/kanafuri_set'
// prettier-ignore
import { Methods as Methods22 } from './v1/shadowing_corrections_sentence_endings'
// prettier-ignore
import { Methods as Methods23 } from './v1/shadowing_items/_shadowing_item_id@number/audio_file'
// prettier-ignore
import { Methods as Methods24 } from './v1/students'
// prettier-ignore
import { Methods as Methods25 } from './v1/students/_student_id@string'
// prettier-ignore
import { Methods as Methods26 } from './v1/students/_student_id@string/courses'
// prettier-ignore
import { Methods as Methods27 } from './v1/students/_student_id@string/courses/_student_course_id@string'
// prettier-ignore
import { Methods as Methods28 } from './v1/students/_student_id@string/courses/_student_course_id@string/update_program_start_date'
// prettier-ignore
import { Methods as Methods29 } from './v1/students/_student_id@string/courses/_student_course_id@string/update_program_start_date_confirm'
// prettier-ignore
import { Methods as Methods30 } from './v1/students/_student_id@string/schedules/_schedule_id@number/other_exam_records'
// prettier-ignore
import { Methods as Methods31 } from './v1/students/_student_id@string/schedules/_schedule_id@number/other_exam_records/_other_exam_record_id@number'
// prettier-ignore
import { Methods as Methods32 } from './v1/students/_student_id@string/schedules/_schedule_id@number/trainings'
// prettier-ignore
import { Methods as Methods33 } from './v1/students/_student_id@string/schedules/_schedule_id@number/trainings/_training_id@number'
// prettier-ignore
import { Methods as Methods34 } from './v1/students/_student_id@string/schedules/_schedule_id@number/versant_exam_records'
// prettier-ignore
import { Methods as Methods35 } from './v1/students/_student_id@string/schedules/_schedule_id@number/versant_exam_records/_versant_exam_record_id@number'
// prettier-ignore
import { Methods as Methods36 } from './v1/students/_student_id@string/schedules/_schedule_id@number/weekly_interview_survey_answers'
// prettier-ignore
import { Methods as Methods37 } from './v1/students/_student_id@string/schedules/_schedule_id@number/weekly_interview_survey_questions'
// prettier-ignore
import { Methods as Methods38 } from './v1/students/_student_id@string/schedules/_schedule_id@number/weeks/_week@number/trainings'
// prettier-ignore
import { Methods as Methods39 } from './v1/students/_student_id@string/schedules/_schedule_id@number/weeks/_week@number/trainings/_training_id@number'
// prettier-ignore
import { Methods as Methods40 } from './v1/study_items'
// prettier-ignore
import { Methods as Methods41 } from './v1/study_materials'
// prettier-ignore
import { Methods as Methods42 } from './v1/study_menus'
// prettier-ignore
import { Methods as Methods43 } from './v2/schedules/_schedule_id@number/info'
// prettier-ignore
import { Methods as Methods44 } from './v2/shadowing/patrol_output'
// prettier-ignore
import { Methods as Methods45 } from './v2/shadowing/students'
// prettier-ignore
import { Methods as Methods46 } from './v2/shadowing/students/_progrit_student_id@number'
// prettier-ignore
import { Methods as Methods47 } from './v2/shadowing/students/_progrit_student_id@number/communication'
// prettier-ignore
import { Methods as Methods48 } from './v2/shadowing_corrections/_shadowing_correction_id@number'
// prettier-ignore
import { Methods as Methods49 } from './v2/shadowing_notes/_shadowing_note_id@number'
// prettier-ignore
import { Methods as Methods50 } from './v2/shadowing_recordings/_student_shadowing_recording_id@number/file'
// prettier-ignore
import { Methods as Methods51 } from './v2/students/_progrit_student_id@number/shadowing_corrections'
// prettier-ignore
import { Methods as Methods52 } from './v2/students/_progrit_student_id@number/shadowing_notes'
// prettier-ignore
import { Methods as Methods53 } from './v2/students/_student_id@string/schedules/_schedule_id@number/entire_schedule'
// prettier-ignore
import { Methods as Methods54 } from './v2/students/_student_id@string/schedules/_schedule_id@number/study_menus'
// prettier-ignore
import { Methods as Methods55 } from './v2/students/_student_id@string/schedules/_schedule_id@number/study_menus/_schedule_study_menu_id@number'
// prettier-ignore
import { Methods as Methods56 } from './v2/students/_student_id@string/schedules/_schedule_id@number/weekly_interviews'
// prettier-ignore
import { Methods as Methods57 } from './v2/students/_student_id@string/schedules/_schedule_id@number/weekly_study_status'
// prettier-ignore
import { Methods as Methods58 } from './v2/students/_student_id@string/schedules/_schedule_id@number/weeks'
// prettier-ignore
import { Methods as Methods59 } from './v2/students/_student_id@string/schedules/_schedule_id@number/weeks/_week@number'
// prettier-ignore
import { Methods as Methods60 } from './v2/students/_student_id@string/schedules/_schedule_id@number/weeks/_week@number/interview'
// prettier-ignore
import { Methods as Methods61 } from './v2/students/_student_id@string/schedules/_schedule_id@number/weeks/_week@number/study_status'
// prettier-ignore
import { Methods as Methods62 } from './v2/students/_student_id@string/schedules/_schedule_id@number/wpm_transitions'
// prettier-ignore
import { Methods as Methods63 } from './v2/students/_student_id@string/student_courses/_student_course_id@string/versant_exam_records'
// prettier-ignore
import { Methods as Methods64 } from './v2/students/_student_id@string/student_courses/_student_course_id@string/versant_exam_records/_versant_exam_record_id@number'
// prettier-ignore
import { Methods as Methods65 } from './v3/data/consultants/sync'
// prettier-ignore
import { Methods as Methods66 } from './v3/schedules/_schedule_id@number/conversation_study_records'
// prettier-ignore
import { Methods as Methods67 } from './v3/schedules/_schedule_id@number/entire_schedule'
// prettier-ignore
import { Methods as Methods68 } from './v3/schedules/_schedule_id@number/exam_study_records'
// prettier-ignore
import { Methods as Methods69 } from './v3/schedules/_schedule_id@number/extensive_reading_study_records'
// prettier-ignore
import { Methods as Methods70 } from './v3/schedules/_schedule_id@number/grammar_study_records'
// prettier-ignore
import { Methods as Methods71 } from './v3/schedules/_schedule_id@number/intensive_reading_study_records'
// prettier-ignore
import { Methods as Methods72 } from './v3/schedules/_schedule_id@number/speed_reading_study_records'
// prettier-ignore
import { Methods as Methods73 } from './v3/trainings/_training_id@number/has_plan'
// prettier-ignore
import { Methods as Methods74 } from './v4/schedules/_schedule_id@number/extensive_reading_study_records'

// prettier-ignore
const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? 'https://api-dev.aesop.progrit.work' : baseURL).replace(/\/$/, '')
  const PATH0 = '/v1/auth/apply'
  const PATH1 = '/v1/auth/login'
  const PATH2 = '/v1/auth/logout'
  const PATH3 = '/v1/auth/password_reset/reset'
  const PATH4 = '/v1/auth/password_reset/tokens'
  const PATH5 = '/v1/auth/password_reset/verify'
  const PATH6 = '/v1/auth/refresh'
  const PATH7 = '/v1/auth/register'
  const PATH8 = '/v1/auth/verify'
  const PATH9 = '/v1/consultants'
  const PATH10 = '/v1/consultants/me'
  const PATH11 = '/v1/consultants/me/icon'
  const PATH12 = '/v1/consultants/me/password'
  const PATH13 = '/v1/consultants/me/school_building'
  const PATH14 = '/v1/course_type_ids'
  const PATH15 = '/v1/data/account_refresh/sync'
  const PATH16 = '/v1/data/shadowing_lock/sync'
  const PATH17 = '/v1/data/student_shadowing_item_id/sync'
  const PATH18 = '/v1/data/study_master/sync'
  const PATH19 = '/v1/incharge_students'
  const PATH20 = '/v1/school_buildings'
  const PATH21 = '/v1/shadowing_corrections'
  const PATH22 = '/kanafuri_set'
  const PATH23 = '/v1/shadowing_corrections_sentence_endings'
  const PATH24 = '/v1/shadowing_items'
  const PATH25 = '/audio_file'
  const PATH26 = '/v1/students'
  const PATH27 = '/courses'
  const PATH28 = '/update_program_start_date'
  const PATH29 = '/update_program_start_date_confirm'
  const PATH30 = '/schedules'
  const PATH31 = '/other_exam_records'
  const PATH32 = '/trainings'
  const PATH33 = '/versant_exam_records'
  const PATH34 = '/weekly_interview_survey_answers'
  const PATH35 = '/weekly_interview_survey_questions'
  const PATH36 = '/weeks'
  const PATH37 = '/v1/study_items'
  const PATH38 = '/v1/study_materials'
  const PATH39 = '/v1/study_menus'
  const PATH40 = '/v2/schedules'
  const PATH41 = '/info'
  const PATH42 = '/v2/shadowing/patrol_output'
  const PATH43 = '/v2/shadowing/students'
  const PATH44 = '/communication'
  const PATH45 = '/v2/shadowing_corrections'
  const PATH46 = '/v2/shadowing_notes'
  const PATH47 = '/v2/shadowing_recordings'
  const PATH48 = '/file'
  const PATH49 = '/v2/students'
  const PATH50 = '/shadowing_corrections'
  const PATH51 = '/shadowing_notes'
  const PATH52 = '/entire_schedule'
  const PATH53 = '/study_menus'
  const PATH54 = '/weekly_interviews'
  const PATH55 = '/weekly_study_status'
  const PATH56 = '/interview'
  const PATH57 = '/study_status'
  const PATH58 = '/wpm_transitions'
  const PATH59 = '/student_courses'
  const PATH60 = '/v3/data/consultants/sync'
  const PATH61 = '/v3/schedules'
  const PATH62 = '/conversation_study_records'
  const PATH63 = '/exam_study_records'
  const PATH64 = '/extensive_reading_study_records'
  const PATH65 = '/grammar_study_records'
  const PATH66 = '/intensive_reading_study_records'
  const PATH67 = '/speed_reading_study_records'
  const PATH68 = '/v3/trainings'
  const PATH69 = '/has_plan'
  const PATH70 = '/v4/schedules'
  const GET = 'GET'
  const POST = 'POST'
  const PUT = 'PUT'
  const DELETE = 'DELETE'

  return {
    v1: {
      auth: {
        apply: {
          /**
           * @returns 成功し、パスワード初期設定メールを送信する
           */
          post: (option: { body: Methods0['post']['reqBody'], config?: T }) =>
            fetch<Methods0['post']['resBody'], BasicHeaders, Methods0['post']['status']>(prefix, PATH0, POST, option).json(),
          /**
           * @returns 成功し、パスワード初期設定メールを送信する
           */
          $post: (option: { body: Methods0['post']['reqBody'], config?: T }) =>
            fetch<Methods0['post']['resBody'], BasicHeaders, Methods0['post']['status']>(prefix, PATH0, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH0}`
        },
        login: {
          /**
           * @returns トークンを返す
           */
          post: (option: { body: Methods1['post']['reqBody'], config?: T }) =>
            fetch<Methods1['post']['resBody'], BasicHeaders, Methods1['post']['status']>(prefix, PATH1, POST, option).json(),
          /**
           * @returns トークンを返す
           */
          $post: (option: { body: Methods1['post']['reqBody'], config?: T }) =>
            fetch<Methods1['post']['resBody'], BasicHeaders, Methods1['post']['status']>(prefix, PATH1, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH1}`
        },
        logout: {
          /**
           * @returns ログアウト成功
           */
          post: (option?: { config?: T }) =>
            fetch<Methods2['post']['resBody'], BasicHeaders, Methods2['post']['status']>(prefix, PATH2, POST, option).json(),
          /**
           * @returns ログアウト成功
           */
          $post: (option?: { config?: T }) =>
            fetch<Methods2['post']['resBody'], BasicHeaders, Methods2['post']['status']>(prefix, PATH2, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH2}`
        },
        password_reset: {
          reset: {
            /**
             * パスワードリセットを行う。
             * @returns 成功
             */
            post: (option: { body: Methods3['post']['reqBody'], config?: T }) =>
              fetch<Methods3['post']['resBody'], BasicHeaders, Methods3['post']['status']>(prefix, PATH3, POST, option).json(),
            /**
             * パスワードリセットを行う。
             * @returns 成功
             */
            $post: (option: { body: Methods3['post']['reqBody'], config?: T }) =>
              fetch<Methods3['post']['resBody'], BasicHeaders, Methods3['post']['status']>(prefix, PATH3, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH3}`
          },
          tokens: {
            /**
             * パスワードリセットトークンを発行し、メールで送信する。
             * @returns 成功
             */
            post: (option: { body: Methods4['post']['reqBody'], config?: T }) =>
              fetch<Methods4['post']['resBody'], BasicHeaders, Methods4['post']['status']>(prefix, PATH4, POST, option).json(),
            /**
             * パスワードリセットトークンを発行し、メールで送信する。
             * @returns 成功
             */
            $post: (option: { body: Methods4['post']['reqBody'], config?: T }) =>
              fetch<Methods4['post']['resBody'], BasicHeaders, Methods4['post']['status']>(prefix, PATH4, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH4}`
          },
          verify: {
            /**
             * パスワードリセットトークンの有効性を確認する。
             *
             * 有効なトークンが見つからない場合、レスポンスで再送信先メールアドレスを返す。
             *
             * (ただし、トークンに紐づくメールアドレスが見つからない場合は、再送信先メールアドレスはnullを返す)
             * @returns 成功
             */
            get: (option: { query: Methods5['get']['query'], config?: T }) =>
              fetch<Methods5['get']['resBody'], BasicHeaders, Methods5['get']['status']>(prefix, PATH5, GET, option).json(),
            /**
             * パスワードリセットトークンの有効性を確認する。
             *
             * 有効なトークンが見つからない場合、レスポンスで再送信先メールアドレスを返す。
             *
             * (ただし、トークンに紐づくメールアドレスが見つからない場合は、再送信先メールアドレスはnullを返す)
             * @returns 成功
             */
            $get: (option: { query: Methods5['get']['query'], config?: T }) =>
              fetch<Methods5['get']['resBody'], BasicHeaders, Methods5['get']['status']>(prefix, PATH5, GET, option).json().then(r => r.body),
            $path: (option?: { method?: 'get'; query: Methods5['get']['query'] }) =>
              `${prefix}${PATH5}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
          }
        },
        refresh: {
          /**
           * @returns 期間延長されたトークンを返却する
           */
          post: (option?: { config?: T }) =>
            fetch<Methods6['post']['resBody'], BasicHeaders, Methods6['post']['status']>(prefix, PATH6, POST, option).json(),
          /**
           * @returns 期間延長されたトークンを返却する
           */
          $post: (option?: { config?: T }) =>
            fetch<Methods6['post']['resBody'], BasicHeaders, Methods6['post']['status']>(prefix, PATH6, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH6}`
        },
        register: {
          /**
           * @returns パスワードを設定しログイン、トークンを返す
           */
          post: (option: { body: Methods7['post']['reqBody'], config?: T }) =>
            fetch<Methods7['post']['resBody'], BasicHeaders, Methods7['post']['status']>(prefix, PATH7, POST, option).json(),
          /**
           * @returns パスワードを設定しログイン、トークンを返す
           */
          $post: (option: { body: Methods7['post']['reqBody'], config?: T }) =>
            fetch<Methods7['post']['resBody'], BasicHeaders, Methods7['post']['status']>(prefix, PATH7, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH7}`
        },
        verify: {
          /**
           * 初期パスワード登録用トークンの有効性を確認する。
           *
           * 有効なトークンが見つからない場合、レスポンスで再送信先メールアドレスを返す。
           *
           * (ただし、トークンに紐づくメールアドレスが見つからない場合は、再送信先メールアドレスはnullを返す)
           * @returns 成功
           */
          get: (option: { query: Methods8['get']['query'], config?: T }) =>
            fetch<Methods8['get']['resBody'], BasicHeaders, Methods8['get']['status']>(prefix, PATH8, GET, option).json(),
          /**
           * 初期パスワード登録用トークンの有効性を確認する。
           *
           * 有効なトークンが見つからない場合、レスポンスで再送信先メールアドレスを返す。
           *
           * (ただし、トークンに紐づくメールアドレスが見つからない場合は、再送信先メールアドレスはnullを返す)
           * @returns 成功
           */
          $get: (option: { query: Methods8['get']['query'], config?: T }) =>
            fetch<Methods8['get']['resBody'], BasicHeaders, Methods8['get']['status']>(prefix, PATH8, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get'; query: Methods8['get']['query'] }) =>
            `${prefix}${PATH8}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
        }
      },
      consultants: {
        me: {
          icon: {
            /**
             * アイコン画像を更新する。
             *
             * ### 画像ファイルの条件
             * - 画像種別: jpg, png, gif
             * - 最大ファイルサイズ: 5MB
             * @returns 成功
             */
            post: (option: { body: Methods11['post']['reqBody'], config?: T }) =>
              fetch<Methods11['post']['resBody'], BasicHeaders, Methods11['post']['status']>(prefix, PATH11, POST, option, 'FormData').json(),
            /**
             * アイコン画像を更新する。
             *
             * ### 画像ファイルの条件
             * - 画像種別: jpg, png, gif
             * - 最大ファイルサイズ: 5MB
             * @returns 成功
             */
            $post: (option: { body: Methods11['post']['reqBody'], config?: T }) =>
              fetch<Methods11['post']['resBody'], BasicHeaders, Methods11['post']['status']>(prefix, PATH11, POST, option, 'FormData').json().then(r => r.body),
            /**
             * アイコン画像を削除する。
             * @returns 成功
             */
            delete: (option?: { config?: T }) =>
              fetch<Methods11['delete']['resBody'], BasicHeaders, Methods11['delete']['status']>(prefix, PATH11, DELETE, option).json(),
            /**
             * アイコン画像を削除する。
             * @returns 成功
             */
            $delete: (option?: { config?: T }) =>
              fetch<Methods11['delete']['resBody'], BasicHeaders, Methods11['delete']['status']>(prefix, PATH11, DELETE, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH11}`
          },
          password: {
            /**
             * パスワードを変更する。
             * @returns 成功
             */
            put: (option: { body: Methods12['put']['reqBody'], config?: T }) =>
              fetch<Methods12['put']['resBody'], BasicHeaders, Methods12['put']['status']>(prefix, PATH12, PUT, option).json(),
            /**
             * パスワードを変更する。
             * @returns 成功
             */
            $put: (option: { body: Methods12['put']['reqBody'], config?: T }) =>
              fetch<Methods12['put']['resBody'], BasicHeaders, Methods12['put']['status']>(prefix, PATH12, PUT, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH12}`
          },
          school_building: {
            /**
             * 所属校舎を変更する
             *
             * 存在しない後者の場合はステータス:422を返す。
             * @returns 成功
             */
            put: (option: { body: Methods13['put']['reqBody'], config?: T }) =>
              fetch<Methods13['put']['resBody'], BasicHeaders, Methods13['put']['status']>(prefix, PATH13, PUT, option).json(),
            /**
             * 所属校舎を変更する
             *
             * 存在しない後者の場合はステータス:422を返す。
             * @returns 成功
             */
            $put: (option: { body: Methods13['put']['reqBody'], config?: T }) =>
              fetch<Methods13['put']['resBody'], BasicHeaders, Methods13['put']['status']>(prefix, PATH13, PUT, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH13}`
          },
          /**
           * ログイン中のコンサルタントを取得する。
           * @returns 成功
           */
          get: (option?: { config?: T }) =>
            fetch<Methods10['get']['resBody'], BasicHeaders, Methods10['get']['status']>(prefix, PATH10, GET, option).json(),
          /**
           * ログイン中のコンサルタントを取得する。
           * @returns 成功
           */
          $get: (option?: { config?: T }) =>
            fetch<Methods10['get']['resBody'], BasicHeaders, Methods10['get']['status']>(prefix, PATH10, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH10}`
        },
        /**
         * コンサルタント一覧を取得する。
         * @returns 成功
         */
        get: (option?: { config?: T }) =>
          fetch<Methods9['get']['resBody'], BasicHeaders, Methods9['get']['status']>(prefix, PATH9, GET, option).json(),
        /**
         * コンサルタント一覧を取得する。
         * @returns 成功
         */
        $get: (option?: { config?: T }) =>
          fetch<Methods9['get']['resBody'], BasicHeaders, Methods9['get']['status']>(prefix, PATH9, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH9}`
      },
      course_type_ids: {
        /**
         * コース検索条件一覧を取得する。
         * @returns 成功
         */
        get: (option?: { config?: T }) =>
          fetch<Methods14['get']['resBody'], BasicHeaders, Methods14['get']['status']>(prefix, PATH14, GET, option).json(),
        /**
         * コース検索条件一覧を取得する。
         * @returns 成功
         */
        $get: (option?: { config?: T }) =>
          fetch<Methods14['get']['resBody'], BasicHeaders, Methods14['get']['status']>(prefix, PATH14, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH14}`
      },
      data: {
        account_refresh: {
          sync: {
            /**
             * データを同期するAPI
             *
             * [スプレットシート](https://docs.google.com/spreadsheets/d/1WLIq7iIKvYoxQZKfFkKuPRIY3CrF8U3TMrA-zWbtOjA/edit#gid=523006916)
             * @returns 成功
             */
            post: (option: { body: Methods15['post']['reqBody'], config?: T }) =>
              fetch<Methods15['post']['resBody'], BasicHeaders, Methods15['post']['status']>(prefix, PATH15, POST, option).json(),
            /**
             * データを同期するAPI
             *
             * [スプレットシート](https://docs.google.com/spreadsheets/d/1WLIq7iIKvYoxQZKfFkKuPRIY3CrF8U3TMrA-zWbtOjA/edit#gid=523006916)
             * @returns 成功
             */
            $post: (option: { body: Methods15['post']['reqBody'], config?: T }) =>
              fetch<Methods15['post']['resBody'], BasicHeaders, Methods15['post']['status']>(prefix, PATH15, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH15}`
          }
        },
        shadowing_lock: {
          sync: {
            /**
             * シャドーイング添削をロックするAPI
             *
             * [スプレットシート](https://docs.google.com/spreadsheets/d/1kAN6uTkIaZ1IOvP4xR5gRowP88_T16ASGtoYdqqQsNQ/edit#gid=1308064237)
             * @returns 成功
             */
            post: (option: { body: Methods16['post']['reqBody'], config?: T }) =>
              fetch<Methods16['post']['resBody'], BasicHeaders, Methods16['post']['status']>(prefix, PATH16, POST, option).json(),
            /**
             * シャドーイング添削をロックするAPI
             *
             * [スプレットシート](https://docs.google.com/spreadsheets/d/1kAN6uTkIaZ1IOvP4xR5gRowP88_T16ASGtoYdqqQsNQ/edit#gid=1308064237)
             * @returns 成功
             */
            $post: (option: { body: Methods16['post']['reqBody'], config?: T }) =>
              fetch<Methods16['post']['resBody'], BasicHeaders, Methods16['post']['status']>(prefix, PATH16, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH16}`
          }
        },
        student_shadowing_item_id: {
          sync: {
            /**
             * データを同期するAPI
             *
             * [スプレットシート](https://docs.google.com/spreadsheets/d/18DRIAlhWwB5UlFgHCcOALbmENUZiUYPSc9eQobDjOv0/edit#gid=523006916)
             *
             * `shadowing_item_id` は変更したいシャドーイングアイテムID [Gonシャドーイングースプレットシート](https://docs.google.com/spreadsheets/d/19j-YrpBOMowJwufeDBJFW2qccSPtXr9VujSFp1Uc8Jg/edit#gid=1451996796) のid列を指定します
             * @returns 成功
             */
            post: (option: { body: Methods17['post']['reqBody'], config?: T }) =>
              fetch<Methods17['post']['resBody'], BasicHeaders, Methods17['post']['status']>(prefix, PATH17, POST, option).json(),
            /**
             * データを同期するAPI
             *
             * [スプレットシート](https://docs.google.com/spreadsheets/d/18DRIAlhWwB5UlFgHCcOALbmENUZiUYPSc9eQobDjOv0/edit#gid=523006916)
             *
             * `shadowing_item_id` は変更したいシャドーイングアイテムID [Gonシャドーイングースプレットシート](https://docs.google.com/spreadsheets/d/19j-YrpBOMowJwufeDBJFW2qccSPtXr9VujSFp1Uc8Jg/edit#gid=1451996796) のid列を指定します
             * @returns 成功
             */
            $post: (option: { body: Methods17['post']['reqBody'], config?: T }) =>
              fetch<Methods17['post']['resBody'], BasicHeaders, Methods17['post']['status']>(prefix, PATH17, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH17}`
          }
        },
        study_master: {
          sync: {
            /**
             * データを同期するAPI
             *
             * [スプレットシート](https://docs.google.com/spreadsheets/d/1fXBdtkNPycRt8Vi8A0iCx_27StMzWUwkqND7lOtK1Vo/edit#gid=778628051)
             * @returns 成功
             */
            post: (option: { body: Methods18['post']['reqBody'], config?: T }) =>
              fetch<Methods18['post']['resBody'], BasicHeaders, Methods18['post']['status']>(prefix, PATH18, POST, option).json(),
            /**
             * データを同期するAPI
             *
             * [スプレットシート](https://docs.google.com/spreadsheets/d/1fXBdtkNPycRt8Vi8A0iCx_27StMzWUwkqND7lOtK1Vo/edit#gid=778628051)
             * @returns 成功
             */
            $post: (option: { body: Methods18['post']['reqBody'], config?: T }) =>
              fetch<Methods18['post']['resBody'], BasicHeaders, Methods18['post']['status']>(prefix, PATH18, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH18}`
          }
        }
      },
      incharge_students: {
        /**
         * プログラム期間中の生徒一覧を条件を指定して取得する
         * @returns 成功
         */
        get: (option?: { query?: Methods19['get']['query'], config?: T }) =>
          fetch<Methods19['get']['resBody'], BasicHeaders, Methods19['get']['status']>(prefix, PATH19, GET, option).json(),
        /**
         * プログラム期間中の生徒一覧を条件を指定して取得する
         * @returns 成功
         */
        $get: (option?: { query?: Methods19['get']['query'], config?: T }) =>
          fetch<Methods19['get']['resBody'], BasicHeaders, Methods19['get']['status']>(prefix, PATH19, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get'; query: Methods19['get']['query'] }) =>
          `${prefix}${PATH19}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      school_buildings: {
        /**
         * 校舎一覧を取得する。
         * @returns 成功
         */
        get: (option?: { config?: T }) =>
          fetch<Methods20['get']['resBody'], BasicHeaders, Methods20['get']['status']>(prefix, PATH20, GET, option).json(),
        /**
         * 校舎一覧を取得する。
         * @returns 成功
         */
        $get: (option?: { config?: T }) =>
          fetch<Methods20['get']['resBody'], BasicHeaders, Methods20['get']['status']>(prefix, PATH20, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH20}`
      },
      shadowing_corrections: {
        _shadowing_correction_id: (val2: number) => {
          const prefix2 = `${PATH21}/${val2}`

          return {
            kanafuri_set: {
              /**
               * シャドーイング添削に紐づくカナフリセットを取得する。
               * @returns 成功
               */
              get: (option?: { config?: T }) =>
                fetch<Methods21['get']['resBody'], BasicHeaders, Methods21['get']['status']>(prefix, `${prefix2}${PATH22}`, GET, option).json(),
              /**
               * シャドーイング添削に紐づくカナフリセットを取得する。
               * @returns 成功
               */
              $get: (option?: { config?: T }) =>
                fetch<Methods21['get']['resBody'], BasicHeaders, Methods21['get']['status']>(prefix, `${prefix2}${PATH22}`, GET, option).json().then(r => r.body),
              /**
               * シャドーイング添削に紐づくカナフリセットを更新する。
               * @returns 成功
               */
              put: (option: { body: Methods21['put']['reqBody'], config?: T }) =>
                fetch<Methods21['put']['resBody'], BasicHeaders, Methods21['put']['status']>(prefix, `${prefix2}${PATH22}`, PUT, option).json(),
              /**
               * シャドーイング添削に紐づくカナフリセットを更新する。
               * @returns 成功
               */
              $put: (option: { body: Methods21['put']['reqBody'], config?: T }) =>
                fetch<Methods21['put']['resBody'], BasicHeaders, Methods21['put']['status']>(prefix, `${prefix2}${PATH22}`, PUT, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH22}`
            }
          }
        }
      },
      shadowing_corrections_sentence_endings: {
        /**
         * 添削コメント語尾マスタ一覧を取得する。
         * @returns 成功
         */
        get: (option?: { config?: T }) =>
          fetch<Methods22['get']['resBody'], BasicHeaders, Methods22['get']['status']>(prefix, PATH23, GET, option).json(),
        /**
         * 添削コメント語尾マスタ一覧を取得する。
         * @returns 成功
         */
        $get: (option?: { config?: T }) =>
          fetch<Methods22['get']['resBody'], BasicHeaders, Methods22['get']['status']>(prefix, PATH23, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH23}`
      },
      shadowing_items: {
        _shadowing_item_id: (val2: number) => {
          const prefix2 = `${PATH24}/${val2}`

          return {
            audio_file: {
              /**
               * シャドーイング音源ファイルを取得する。
               *
               * Acceptヘッダーはapplication/octet-streamを指定しなければならないことに注意する。
               * @returns 成功
               */
              get: (option?: { config?: T }) =>
                fetch<Methods23['get']['resBody'], BasicHeaders, Methods23['get']['status']>(prefix, `${prefix2}${PATH25}`, GET, option).blob(),
              /**
               * シャドーイング音源ファイルを取得する。
               *
               * Acceptヘッダーはapplication/octet-streamを指定しなければならないことに注意する。
               * @returns 成功
               */
              $get: (option?: { config?: T }) =>
                fetch<Methods23['get']['resBody'], BasicHeaders, Methods23['get']['status']>(prefix, `${prefix2}${PATH25}`, GET, option).blob().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH25}`
            }
          }
        }
      },
      students: {
        _student_id: (val2: string) => {
          const prefix2 = `${PATH26}/${val2}`

          return {
            courses: {
              _student_course_id: (val4: string) => {
                const prefix4 = `${prefix2}${PATH27}/${val4}`

                return {
                  update_program_start_date: {
                    /**
                     * 受講コースの開始日を登録/更新する。
                     *
                     * - 登録の場合
                     *   - 全体スケジュールの週、目的の初期設定を登録する。
                     * - 更新の場合
                     *   - 全体スケジュールの週設定の開始日、終了日を更新する。
                     *   - 学習予定、学習記録が存在する場合、削除する。
                     * @returns 成功
                     */
                    post: (option: { body: Methods28['post']['reqBody'], config?: T }) =>
                      fetch<Methods28['post']['resBody'], BasicHeaders, Methods28['post']['status']>(prefix, `${prefix4}${PATH28}`, POST, option).json(),
                    /**
                     * 受講コースの開始日を登録/更新する。
                     *
                     * - 登録の場合
                     *   - 全体スケジュールの週、目的の初期設定を登録する。
                     * - 更新の場合
                     *   - 全体スケジュールの週設定の開始日、終了日を更新する。
                     *   - 学習予定、学習記録が存在する場合、削除する。
                     * @returns 成功
                     */
                    $post: (option: { body: Methods28['post']['reqBody'], config?: T }) =>
                      fetch<Methods28['post']['resBody'], BasicHeaders, Methods28['post']['status']>(prefix, `${prefix4}${PATH28}`, POST, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix4}${PATH28}`
                  },
                  update_program_start_date_confirm: {
                    /**
                     * 受講コース開始日の更新確認を行う。
                     *
                     * 学習予定または学習記録が存在する場合、`exist_data_flg`で`true`を返す。
                     * @returns 成功
                     */
                    post: (option: { body: Methods29['post']['reqBody'], config?: T }) =>
                      fetch<Methods29['post']['resBody'], BasicHeaders, Methods29['post']['status']>(prefix, `${prefix4}${PATH29}`, POST, option).json(),
                    /**
                     * 受講コース開始日の更新確認を行う。
                     *
                     * 学習予定または学習記録が存在する場合、`exist_data_flg`で`true`を返す。
                     * @returns 成功
                     */
                    $post: (option: { body: Methods29['post']['reqBody'], config?: T }) =>
                      fetch<Methods29['post']['resBody'], BasicHeaders, Methods29['post']['status']>(prefix, `${prefix4}${PATH29}`, POST, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix4}${PATH29}`
                  },
                  /**
                   * 受講コースを更新する。
                   *
                   * - active_flgはtrueのみ指定可能。trueで指定されたコース以外はfalseで更新される。
                   * - 受講コース開始日は別API (受講コース開始日の登録/更新API) にて行う。
                   * @returns 成功
                   */
                  put: (option: { body: Methods27['put']['reqBody'], config?: T }) =>
                    fetch<Methods27['put']['resBody'], BasicHeaders, Methods27['put']['status']>(prefix, prefix4, PUT, option).json(),
                  /**
                   * 受講コースを更新する。
                   *
                   * - active_flgはtrueのみ指定可能。trueで指定されたコース以外はfalseで更新される。
                   * - 受講コース開始日は別API (受講コース開始日の登録/更新API) にて行う。
                   * @returns 成功
                   */
                  $put: (option: { body: Methods27['put']['reqBody'], config?: T }) =>
                    fetch<Methods27['put']['resBody'], BasicHeaders, Methods27['put']['status']>(prefix, prefix4, PUT, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix4}`
                }
              },
              /**
               * 受講コース一覧を取得する。
               *
               * 一覧は開始日, id順でソートされる
               * @returns 成功
               */
              get: (option?: { config?: T }) =>
                fetch<Methods26['get']['resBody'], BasicHeaders, Methods26['get']['status']>(prefix, `${prefix2}${PATH27}`, GET, option).json(),
              /**
               * 受講コース一覧を取得する。
               *
               * 一覧は開始日, id順でソートされる
               * @returns 成功
               */
              $get: (option?: { config?: T }) =>
                fetch<Methods26['get']['resBody'], BasicHeaders, Methods26['get']['status']>(prefix, `${prefix2}${PATH27}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH27}`
            },
            schedules: {
              _schedule_id: (val4: number) => {
                const prefix4 = `${prefix2}${PATH30}/${val4}`

                return {
                  other_exam_records: {
                    _other_exam_record_id: (val6: number) => {
                      const prefix6 = `${prefix4}${PATH31}/${val6}`

                      return {
                        /**
                         * その他テスト記録を更新する。
                         * @returns 成功
                         */
                        put: (option: { body: Methods31['put']['reqBody'], config?: T }) =>
                          fetch<Methods31['put']['resBody'], BasicHeaders, Methods31['put']['status']>(prefix, prefix6, PUT, option).json(),
                        /**
                         * その他テスト記録を更新する。
                         * @returns 成功
                         */
                        $put: (option: { body: Methods31['put']['reqBody'], config?: T }) =>
                          fetch<Methods31['put']['resBody'], BasicHeaders, Methods31['put']['status']>(prefix, prefix6, PUT, option).json().then(r => r.body),
                        /**
                         * その他テスト記録を削除する。
                         * @returns 成功
                         */
                        delete: (option?: { config?: T }) =>
                          fetch<Methods31['delete']['resBody'], BasicHeaders, Methods31['delete']['status']>(prefix, prefix6, DELETE, option).json(),
                        /**
                         * その他テスト記録を削除する。
                         * @returns 成功
                         */
                        $delete: (option?: { config?: T }) =>
                          fetch<Methods31['delete']['resBody'], BasicHeaders, Methods31['delete']['status']>(prefix, prefix6, DELETE, option).json().then(r => r.body),
                        $path: () => `${prefix}${prefix6}`
                      }
                    },
                    /**
                     * その他テスト記録一覧を取得する。
                     * @returns 成功
                     */
                    get: (option?: { config?: T }) =>
                      fetch<Methods30['get']['resBody'], BasicHeaders, Methods30['get']['status']>(prefix, `${prefix4}${PATH31}`, GET, option).json(),
                    /**
                     * その他テスト記録一覧を取得する。
                     * @returns 成功
                     */
                    $get: (option?: { config?: T }) =>
                      fetch<Methods30['get']['resBody'], BasicHeaders, Methods30['get']['status']>(prefix, `${prefix4}${PATH31}`, GET, option).json().then(r => r.body),
                    /**
                     * その他テスト記録を登録する。
                     * @returns 成功
                     */
                    post: (option: { body: Methods30['post']['reqBody'], config?: T }) =>
                      fetch<Methods30['post']['resBody'], BasicHeaders, Methods30['post']['status']>(prefix, `${prefix4}${PATH31}`, POST, option).json(),
                    /**
                     * その他テスト記録を登録する。
                     * @returns 成功
                     */
                    $post: (option: { body: Methods30['post']['reqBody'], config?: T }) =>
                      fetch<Methods30['post']['resBody'], BasicHeaders, Methods30['post']['status']>(prefix, `${prefix4}${PATH31}`, POST, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix4}${PATH31}`
                  },
                  trainings: {
                    _training_id: (val6: number) => {
                      const prefix6 = `${prefix4}${PATH32}/${val6}`

                      return {
                        /**
                         * トレーニングを取得する。
                         * @returns 成功
                         */
                        get: (option?: { config?: T }) =>
                          fetch<Methods33['get']['resBody'], BasicHeaders, Methods33['get']['status']>(prefix, prefix6, GET, option).json(),
                        /**
                         * トレーニングを取得する。
                         * @returns 成功
                         */
                        $get: (option?: { config?: T }) =>
                          fetch<Methods33['get']['resBody'], BasicHeaders, Methods33['get']['status']>(prefix, prefix6, GET, option).json().then(r => r.body),
                        /**
                         * トレーニングを更新する。
                         *
                         * 開始週、終了週、スケジュール学習メニュー設定IDが重複している場合は422を返す。
                         * @returns 成功
                         */
                        put: (option: { body: Methods33['put']['reqBody'], config?: T }) =>
                          fetch<Methods33['put']['resBody'], BasicHeaders, Methods33['put']['status']>(prefix, prefix6, PUT, option).json(),
                        /**
                         * トレーニングを更新する。
                         *
                         * 開始週、終了週、スケジュール学習メニュー設定IDが重複している場合は422を返す。
                         * @returns 成功
                         */
                        $put: (option: { body: Methods33['put']['reqBody'], config?: T }) =>
                          fetch<Methods33['put']['resBody'], BasicHeaders, Methods33['put']['status']>(prefix, prefix6, PUT, option).json().then(r => r.body),
                        /**
                         * トレーニングを削除する。
                         *
                         * ### 削除可能条件
                         * - トレーニングに紐づく学習予定が存在しないこと
                         * - 上記条件を満たさない場合、422を返す
                         * @returns 成功
                         */
                        delete: (option?: { config?: T }) =>
                          fetch<Methods33['delete']['resBody'], BasicHeaders, Methods33['delete']['status']>(prefix, prefix6, DELETE, option).json(),
                        /**
                         * トレーニングを削除する。
                         *
                         * ### 削除可能条件
                         * - トレーニングに紐づく学習予定が存在しないこと
                         * - 上記条件を満たさない場合、422を返す
                         * @returns 成功
                         */
                        $delete: (option?: { config?: T }) =>
                          fetch<Methods33['delete']['resBody'], BasicHeaders, Methods33['delete']['status']>(prefix, prefix6, DELETE, option).json().then(r => r.body),
                        $path: () => `${prefix}${prefix6}`
                      }
                    },
                    /**
                     * トレーニングを登録する。
                     *
                     * 開始週、終了週、スケジュール学習メニュー設定IDが重複している場合は422を返す。
                     * @returns 成功
                     */
                    post: (option: { body: Methods32['post']['reqBody'], config?: T }) =>
                      fetch<Methods32['post']['resBody'], BasicHeaders, Methods32['post']['status']>(prefix, `${prefix4}${PATH32}`, POST, option).json(),
                    /**
                     * トレーニングを登録する。
                     *
                     * 開始週、終了週、スケジュール学習メニュー設定IDが重複している場合は422を返す。
                     * @returns 成功
                     */
                    $post: (option: { body: Methods32['post']['reqBody'], config?: T }) =>
                      fetch<Methods32['post']['resBody'], BasicHeaders, Methods32['post']['status']>(prefix, `${prefix4}${PATH32}`, POST, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix4}${PATH32}`
                  },
                  versant_exam_records: {
                    _versant_exam_record_id: (val6: number) => {
                      const prefix6 = `${prefix4}${PATH33}/${val6}`

                      return {
                        /**
                         * VERSANTテスト記録を更新する。
                         * @returns 成功
                         */
                        put: (option: { body: Methods35['put']['reqBody'], config?: T }) =>
                          fetch<Methods35['put']['resBody'], BasicHeaders, Methods35['put']['status']>(prefix, prefix6, PUT, option).json(),
                        /**
                         * VERSANTテスト記録を更新する。
                         * @returns 成功
                         */
                        $put: (option: { body: Methods35['put']['reqBody'], config?: T }) =>
                          fetch<Methods35['put']['resBody'], BasicHeaders, Methods35['put']['status']>(prefix, prefix6, PUT, option).json().then(r => r.body),
                        /**
                         * VERSANTテスト記録を削除する。
                         * @returns 成功
                         */
                        delete: (option?: { config?: T }) =>
                          fetch<Methods35['delete']['resBody'], BasicHeaders, Methods35['delete']['status']>(prefix, prefix6, DELETE, option).json(),
                        /**
                         * VERSANTテスト記録を削除する。
                         * @returns 成功
                         */
                        $delete: (option?: { config?: T }) =>
                          fetch<Methods35['delete']['resBody'], BasicHeaders, Methods35['delete']['status']>(prefix, prefix6, DELETE, option).json().then(r => r.body),
                        $path: () => `${prefix}${prefix6}`
                      }
                    },
                    /**
                     * VERSANTテスト記録一覧を取得する。
                     * @returns 成功
                     */
                    get: (option?: { config?: T }) =>
                      fetch<Methods34['get']['resBody'], BasicHeaders, Methods34['get']['status']>(prefix, `${prefix4}${PATH33}`, GET, option).json(),
                    /**
                     * VERSANTテスト記録一覧を取得する。
                     * @returns 成功
                     */
                    $get: (option?: { config?: T }) =>
                      fetch<Methods34['get']['resBody'], BasicHeaders, Methods34['get']['status']>(prefix, `${prefix4}${PATH33}`, GET, option).json().then(r => r.body),
                    /**
                     * VERSANTテスト記録を登録する。
                     * @returns 成功
                     */
                    post: (option: { body: Methods34['post']['reqBody'], config?: T }) =>
                      fetch<Methods34['post']['resBody'], BasicHeaders, Methods34['post']['status']>(prefix, `${prefix4}${PATH33}`, POST, option).json(),
                    /**
                     * VERSANTテスト記録を登録する。
                     * @returns 成功
                     */
                    $post: (option: { body: Methods34['post']['reqBody'], config?: T }) =>
                      fetch<Methods34['post']['resBody'], BasicHeaders, Methods34['post']['status']>(prefix, `${prefix4}${PATH33}`, POST, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix4}${PATH33}`
                  },
                  weekly_interview_survey_answers: {
                    /**
                     * 週間面談 定量アンケート回答一覧を取得する。
                     *
                     * - 回答が未回答の場合、`answer: null`を返す
                     * - マスタが途中で追加された場合、項目自体が返らない
                     *
                     * - レスポンスのソート条件
                     *   - 第一条件: weekの昇順
                     *   - 第二条件: weekly_interview_survey_questions.display_order (右側の凡例の「学習時間の確保」「集中度」...と同じ順序)
                     *
                     * - [表示仕様メモ](https://github.com/progrit/consultingAppManagementFront/issues/438#issuecomment-730969991)
                     * @returns 成功
                     */
                    get: (option?: { config?: T }) =>
                      fetch<Methods36['get']['resBody'], BasicHeaders, Methods36['get']['status']>(prefix, `${prefix4}${PATH34}`, GET, option).json(),
                    /**
                     * 週間面談 定量アンケート回答一覧を取得する。
                     *
                     * - 回答が未回答の場合、`answer: null`を返す
                     * - マスタが途中で追加された場合、項目自体が返らない
                     *
                     * - レスポンスのソート条件
                     *   - 第一条件: weekの昇順
                     *   - 第二条件: weekly_interview_survey_questions.display_order (右側の凡例の「学習時間の確保」「集中度」...と同じ順序)
                     *
                     * - [表示仕様メモ](https://github.com/progrit/consultingAppManagementFront/issues/438#issuecomment-730969991)
                     * @returns 成功
                     */
                    $get: (option?: { config?: T }) =>
                      fetch<Methods36['get']['resBody'], BasicHeaders, Methods36['get']['status']>(prefix, `${prefix4}${PATH34}`, GET, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix4}${PATH34}`
                  },
                  weekly_interview_survey_questions: {
                    /**
                     * 週間面談 定量アンケート質問一覧を取得する。
                     *   - マスタから削除された項目でも回答が存在する場合は含める
                     *   - 凡例表示で使用
                     * @returns 成功
                     */
                    get: (option?: { config?: T }) =>
                      fetch<Methods37['get']['resBody'], BasicHeaders, Methods37['get']['status']>(prefix, `${prefix4}${PATH35}`, GET, option).json(),
                    /**
                     * 週間面談 定量アンケート質問一覧を取得する。
                     *   - マスタから削除された項目でも回答が存在する場合は含める
                     *   - 凡例表示で使用
                     * @returns 成功
                     */
                    $get: (option?: { config?: T }) =>
                      fetch<Methods37['get']['resBody'], BasicHeaders, Methods37['get']['status']>(prefix, `${prefix4}${PATH35}`, GET, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix4}${PATH35}`
                  },
                  weeks: {
                    _week: (val6: number) => {
                      const prefix6 = `${prefix4}${PATH36}/${val6}`

                      return {
                        trainings: {
                          _training_id: (val8: number) => {
                            const prefix8 = `${prefix6}${PATH32}/${val8}`

                            return {
                              /**
                               * 週間トレーニングを取得する。
                               *
                               * weekly_trainingsテーブルにデータがない場合はtrainingsテーブルから取得したデータを返す。
                               * @returns 成功
                               */
                              get: (option?: { config?: T }) =>
                                fetch<Methods39['get']['resBody'], BasicHeaders, Methods39['get']['status']>(prefix, prefix8, GET, option).json(),
                              /**
                               * 週間トレーニングを取得する。
                               *
                               * weekly_trainingsテーブルにデータがない場合はtrainingsテーブルから取得したデータを返す。
                               * @returns 成功
                               */
                              $get: (option?: { config?: T }) =>
                                fetch<Methods39['get']['resBody'], BasicHeaders, Methods39['get']['status']>(prefix, prefix8, GET, option).json().then(r => r.body),
                              /**
                               * 週間トレーニングを登録する。
                               *
                               * 指定されたtraining_id, weekのデータが既に存在する場合は更新する。
                               * @returns 成功
                               */
                              put: (option: { body: Methods39['put']['reqBody'], config?: T }) =>
                                fetch<Methods39['put']['resBody'], BasicHeaders, Methods39['put']['status']>(prefix, prefix8, PUT, option).json(),
                              /**
                               * 週間トレーニングを登録する。
                               *
                               * 指定されたtraining_id, weekのデータが既に存在する場合は更新する。
                               * @returns 成功
                               */
                              $put: (option: { body: Methods39['put']['reqBody'], config?: T }) =>
                                fetch<Methods39['put']['resBody'], BasicHeaders, Methods39['put']['status']>(prefix, prefix8, PUT, option).json().then(r => r.body),
                              $path: () => `${prefix}${prefix8}`
                            }
                          },
                          /**
                           * 週間トレーニング一覧を取得する。
                           *
                           * weekly_trainingsテーブルにデータがない場合はtrainingsテーブルから取得したデータを返す。
                           * @returns 成功
                           */
                          get: (option?: { config?: T }) =>
                            fetch<Methods38['get']['resBody'], BasicHeaders, Methods38['get']['status']>(prefix, `${prefix6}${PATH32}`, GET, option).json(),
                          /**
                           * 週間トレーニング一覧を取得する。
                           *
                           * weekly_trainingsテーブルにデータがない場合はtrainingsテーブルから取得したデータを返す。
                           * @returns 成功
                           */
                          $get: (option?: { config?: T }) =>
                            fetch<Methods38['get']['resBody'], BasicHeaders, Methods38['get']['status']>(prefix, `${prefix6}${PATH32}`, GET, option).json().then(r => r.body),
                          $path: () => `${prefix}${prefix6}${PATH32}`
                        }
                      }
                    }
                  }
                }
              }
            },
            /**
             * 生徒IDを指定して取得する。
             * @returns 成功
             */
            get: (option?: { config?: T }) =>
              fetch<Methods25['get']['resBody'], BasicHeaders, Methods25['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * 生徒IDを指定して取得する。
             * @returns 成功
             */
            $get: (option?: { config?: T }) =>
              fetch<Methods25['get']['resBody'], BasicHeaders, Methods25['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            /**
             * 生徒の受講校舎、担当コンサルタントを更新する
             * @returns 成功
             */
            put: (option: { body: Methods25['put']['reqBody'], config?: T }) =>
              fetch<Methods25['put']['resBody'], BasicHeaders, Methods25['put']['status']>(prefix, prefix2, PUT, option).json(),
            /**
             * 生徒の受講校舎、担当コンサルタントを更新する
             * @returns 成功
             */
            $put: (option: { body: Methods25['put']['reqBody'], config?: T }) =>
              fetch<Methods25['put']['resBody'], BasicHeaders, Methods25['put']['status']>(prefix, prefix2, PUT, option).json().then(r => r.body),
            $path: () => `${prefix}${prefix2}`
          }
        },
        /**
         * 生徒一覧を条件を指定して取得する。
         * @returns 成功
         */
        get: (option?: { query?: Methods24['get']['query'], config?: T }) =>
          fetch<Methods24['get']['resBody'], BasicHeaders, Methods24['get']['status']>(prefix, PATH26, GET, option).json(),
        /**
         * 生徒一覧を条件を指定して取得する。
         * @returns 成功
         */
        $get: (option?: { query?: Methods24['get']['query'], config?: T }) =>
          fetch<Methods24['get']['resBody'], BasicHeaders, Methods24['get']['status']>(prefix, PATH26, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get'; query: Methods24['get']['query'] }) =>
          `${prefix}${PATH26}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      study_items: {
        /**
         * 学習項目マスタ一覧を取得する。
         * @returns 成功
         */
        get: (option: { query: Methods40['get']['query'], config?: T }) =>
          fetch<Methods40['get']['resBody'], BasicHeaders, Methods40['get']['status']>(prefix, PATH37, GET, option).json(),
        /**
         * 学習項目マスタ一覧を取得する。
         * @returns 成功
         */
        $get: (option: { query: Methods40['get']['query'], config?: T }) =>
          fetch<Methods40['get']['resBody'], BasicHeaders, Methods40['get']['status']>(prefix, PATH37, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get'; query: Methods40['get']['query'] }) =>
          `${prefix}${PATH37}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      study_materials: {
        /**
         * 学習教材マスタ一覧を取得する。
         * @returns 成功
         */
        get: (option: { query: Methods41['get']['query'], config?: T }) =>
          fetch<Methods41['get']['resBody'], BasicHeaders, Methods41['get']['status']>(prefix, PATH38, GET, option).json(),
        /**
         * 学習教材マスタ一覧を取得する。
         * @returns 成功
         */
        $get: (option: { query: Methods41['get']['query'], config?: T }) =>
          fetch<Methods41['get']['resBody'], BasicHeaders, Methods41['get']['status']>(prefix, PATH38, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get'; query: Methods41['get']['query'] }) =>
          `${prefix}${PATH38}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      study_menus: {
        /**
         * 学習メニューマスタ一覧を取得する。
         * @returns 成功
         */
        get: (option?: { config?: T }) =>
          fetch<Methods42['get']['resBody'], BasicHeaders, Methods42['get']['status']>(prefix, PATH39, GET, option).json(),
        /**
         * 学習メニューマスタ一覧を取得する。
         * @returns 成功
         */
        $get: (option?: { config?: T }) =>
          fetch<Methods42['get']['resBody'], BasicHeaders, Methods42['get']['status']>(prefix, PATH39, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH39}`
      }
    },
    v2: {
      schedules: {
        _schedule_id: (val2: number) => {
          const prefix2 = `${PATH40}/${val2}`

          return {
            info: {
              /**
               * @returns 成功
               */
              get: (option?: { config?: T }) =>
                fetch<Methods43['get']['resBody'], BasicHeaders, Methods43['get']['status']>(prefix, `${prefix2}${PATH41}`, GET, option).json(),
              /**
               * @returns 成功
               */
              $get: (option?: { config?: T }) =>
                fetch<Methods43['get']['resBody'], BasicHeaders, Methods43['get']['status']>(prefix, `${prefix2}${PATH41}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH41}`
            }
          }
        }
      },
      shadowing: {
        patrol_output: {
          /**
           * パト出力情報を取得する。
           *
           * 添削タグの指定値によらず一律未添削データのみ対象とする。
           * @returns 成功
           */
          get: (option?: { query?: Methods44['get']['query'], config?: T }) =>
            fetch<Methods44['get']['resBody'], BasicHeaders, Methods44['get']['status']>(prefix, PATH42, GET, option).json(),
          /**
           * パト出力情報を取得する。
           *
           * 添削タグの指定値によらず一律未添削データのみ対象とする。
           * @returns 成功
           */
          $get: (option?: { query?: Methods44['get']['query'], config?: T }) =>
            fetch<Methods44['get']['resBody'], BasicHeaders, Methods44['get']['status']>(prefix, PATH42, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get'; query: Methods44['get']['query'] }) =>
            `${prefix}${PATH42}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
        },
        students: {
          _progrit_student_id: (val3: number) => {
            const prefix3 = `${PATH43}/${val3}`

            return {
              communication: {
                /**
                 * 生徒の伝達事項を更新
                 * @returns 成功
                 */
                put: (option: { body: Methods47['put']['reqBody'], config?: T }) =>
                  fetch<Methods47['put']['resBody'], BasicHeaders, Methods47['put']['status']>(prefix, `${prefix3}${PATH44}`, PUT, option).json(),
                /**
                 * 生徒の伝達事項を更新
                 * @returns 成功
                 */
                $put: (option: { body: Methods47['put']['reqBody'], config?: T }) =>
                  fetch<Methods47['put']['resBody'], BasicHeaders, Methods47['put']['status']>(prefix, `${prefix3}${PATH44}`, PUT, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix3}${PATH44}`
              },
              /**
               * シャドーイング添削の生徒情報を取得する。
               * @returns 成功
               */
              get: (option?: { config?: T }) =>
                fetch<Methods46['get']['resBody'], BasicHeaders, Methods46['get']['status']>(prefix, prefix3, GET, option).json(),
              /**
               * シャドーイング添削の生徒情報を取得する。
               * @returns 成功
               */
              $get: (option?: { config?: T }) =>
                fetch<Methods46['get']['resBody'], BasicHeaders, Methods46['get']['status']>(prefix, prefix3, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix3}`
            }
          },
          /**
           * シャドーイング添削の生徒一覧を取得する。
           *
           * ### ステータス 検索仕様
           *
           * - テーブル名student_coursesにおいて、下記2条件をともに満足するときステータスは受講中となり、受講中と判定されたコースを添削アクティブコースと呼ぶ。
           *   ```
           *     [受講中の条件]
           *       ①解約日を過ぎていない
           *         Today < 解約日 + 1
           *         ※解約日当日は受講中とみなさない
           *
           *       ②開始日〜終了日の中に収まっている
           *         SFプログラム開始日 ≦ Today ≦ SFプログラム終了日 + 1
           *         ※開始日および終了日当日は受講中とみなす
           *
           *     [補足条件]
           *       - SFプログラム開始日がnullの場合 →「非受講」
           *       - SFプログラム終了日がnullの場合 →「開始日〜終了日の中に収まっている」
           *       - SF解約日がnullの場合 →「解約日を過ぎていない」
           *   ```
           *
           * - 添削アクティブコースが一つでもあれば当該生徒のステータスは受講中となり、シャドーイング添削の検索条件「ステータス」における受講中で表示対象となる。
           *
           * ### 添削タグ 検索仕様
           *
           * - 未添削: 未添削が一つ以上存在する生徒
           * - 添削済み: すべての録音音声が添削済みの生徒
           *
           * ### コース検索 検索仕様
           *
           * - アクティブでないコースも検索対象とする
           *
           * ### レスポンス
           *
           * - `student_shadowing_correction`フィールド
           *   - 未添削音声が存在する場合
           *     - 最も古い未添削音声の添削データを返す
           *   - 未添削音声が存在しない場合
           *     - 添削済み or 対応不要の音声のうち最も送信時間が新しい添削データを返す
           *
           * 生徒一覧の仕様詳細は下記参照。
           * - [Product/Gon(学習アプリ)/シャドーイング添削FB反映機能](https://progrit-dev.esa.io/posts/134#2.%20%E7%94%9F%E5%BE%92%E4%B8%80%E8%A6%A7%E7%94%BB%E9%9D%A2)
           *
           * ステータス検索(添削アクティブコース)の詳細は下記参照。
           * - [Product/Gon(学習アプリ)/シャドーイング添削_アクティブコース](https://progrit-dev.esa.io/posts/185)
           *
           * nextLink: 次ページのデータのリンク
           * @returns 成功
           */
          get: (option: { query: Methods45['get']['query'], config?: T }) =>
            fetch<Methods45['get']['resBody'], BasicHeaders, Methods45['get']['status']>(prefix, PATH43, GET, option).json(),
          /**
           * シャドーイング添削の生徒一覧を取得する。
           *
           * ### ステータス 検索仕様
           *
           * - テーブル名student_coursesにおいて、下記2条件をともに満足するときステータスは受講中となり、受講中と判定されたコースを添削アクティブコースと呼ぶ。
           *   ```
           *     [受講中の条件]
           *       ①解約日を過ぎていない
           *         Today < 解約日 + 1
           *         ※解約日当日は受講中とみなさない
           *
           *       ②開始日〜終了日の中に収まっている
           *         SFプログラム開始日 ≦ Today ≦ SFプログラム終了日 + 1
           *         ※開始日および終了日当日は受講中とみなす
           *
           *     [補足条件]
           *       - SFプログラム開始日がnullの場合 →「非受講」
           *       - SFプログラム終了日がnullの場合 →「開始日〜終了日の中に収まっている」
           *       - SF解約日がnullの場合 →「解約日を過ぎていない」
           *   ```
           *
           * - 添削アクティブコースが一つでもあれば当該生徒のステータスは受講中となり、シャドーイング添削の検索条件「ステータス」における受講中で表示対象となる。
           *
           * ### 添削タグ 検索仕様
           *
           * - 未添削: 未添削が一つ以上存在する生徒
           * - 添削済み: すべての録音音声が添削済みの生徒
           *
           * ### コース検索 検索仕様
           *
           * - アクティブでないコースも検索対象とする
           *
           * ### レスポンス
           *
           * - `student_shadowing_correction`フィールド
           *   - 未添削音声が存在する場合
           *     - 最も古い未添削音声の添削データを返す
           *   - 未添削音声が存在しない場合
           *     - 添削済み or 対応不要の音声のうち最も送信時間が新しい添削データを返す
           *
           * 生徒一覧の仕様詳細は下記参照。
           * - [Product/Gon(学習アプリ)/シャドーイング添削FB反映機能](https://progrit-dev.esa.io/posts/134#2.%20%E7%94%9F%E5%BE%92%E4%B8%80%E8%A6%A7%E7%94%BB%E9%9D%A2)
           *
           * ステータス検索(添削アクティブコース)の詳細は下記参照。
           * - [Product/Gon(学習アプリ)/シャドーイング添削_アクティブコース](https://progrit-dev.esa.io/posts/185)
           *
           * nextLink: 次ページのデータのリンク
           * @returns 成功
           */
          $get: (option: { query: Methods45['get']['query'], config?: T }) =>
            fetch<Methods45['get']['resBody'], BasicHeaders, Methods45['get']['status']>(prefix, PATH43, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get'; query: Methods45['get']['query'] }) =>
            `${prefix}${PATH43}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
        }
      },
      shadowing_corrections: {
        _shadowing_correction_id: (val2: number) => {
          const prefix2 = `${PATH45}/${val2}`

          return {
            /**
             * シャドーイング添削詳細を取得する。
             * @returns 成功
             */
            get: (option?: { config?: T }) =>
              fetch<Methods48['get']['resBody'], BasicHeaders, Methods48['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * シャドーイング添削詳細を取得する。
             * @returns 成功
             */
            $get: (option?: { config?: T }) =>
              fetch<Methods48['get']['resBody'], BasicHeaders, Methods48['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            /**
             * シャドーイング添削詳細を更新する。
             *
             * 下記のユースケースに合わせて更新対象のパラメータを指定する。
             *
             * | ユースケース | 対象パラメータ |
             * | --- | --- |
             * | 添削テキスト更新 | correction_text |
             * | 対応中フラグ更新 | assigned_consultant_id |
             * | 添削タグ更新 | status |
             *
             * パラメータで指定されたrevisionとDBの `revision` が一致する場合のみ、更新を行う。(このときrevisionをインクリメントする)
             *
             * サーバーで発行した `corrected_at`, `updated_at` を返す必要があるため、更新成功時は最新の添削データを返す。
             *
             * `revision` が一致しない場合、HTTPステータス:409、レスポンスボディ: `updated_consultant_id`, `updated_consultant_name`, `updated_at` を返す。
             * @returns 成功
             */
            put: (option: { body: Methods48['put']['reqBody'], config?: T }) =>
              fetch<Methods48['put']['resBody'], BasicHeaders, Methods48['put']['status']>(prefix, prefix2, PUT, option).json(),
            /**
             * シャドーイング添削詳細を更新する。
             *
             * 下記のユースケースに合わせて更新対象のパラメータを指定する。
             *
             * | ユースケース | 対象パラメータ |
             * | --- | --- |
             * | 添削テキスト更新 | correction_text |
             * | 対応中フラグ更新 | assigned_consultant_id |
             * | 添削タグ更新 | status |
             *
             * パラメータで指定されたrevisionとDBの `revision` が一致する場合のみ、更新を行う。(このときrevisionをインクリメントする)
             *
             * サーバーで発行した `corrected_at`, `updated_at` を返す必要があるため、更新成功時は最新の添削データを返す。
             *
             * `revision` が一致しない場合、HTTPステータス:409、レスポンスボディ: `updated_consultant_id`, `updated_consultant_name`, `updated_at` を返す。
             * @returns 成功
             */
            $put: (option: { body: Methods48['put']['reqBody'], config?: T }) =>
              fetch<Methods48['put']['resBody'], BasicHeaders, Methods48['put']['status']>(prefix, prefix2, PUT, option).json().then(r => r.body),
            $path: () => `${prefix}${prefix2}`
          }
        }
      },
      shadowing_notes: {
        _shadowing_note_id: (val2: number) => {
          const prefix2 = `${PATH46}/${val2}`

          return {
            /**
             * シャドーイングノート詳細を取得する。
             * @returns 成功
             */
            get: (option?: { config?: T }) =>
              fetch<Methods49['get']['resBody'], BasicHeaders, Methods49['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * シャドーイングノート詳細を取得する。
             * @returns 成功
             */
            $get: (option?: { config?: T }) =>
              fetch<Methods49['get']['resBody'], BasicHeaders, Methods49['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            /**
             * シャドーイングノートを更新する。
             * @returns 成功
             */
            put: (option: { body: Methods49['put']['reqBody'], config?: T }) =>
              fetch<Methods49['put']['resBody'], BasicHeaders, Methods49['put']['status']>(prefix, prefix2, PUT, option).json(),
            /**
             * シャドーイングノートを更新する。
             * @returns 成功
             */
            $put: (option: { body: Methods49['put']['reqBody'], config?: T }) =>
              fetch<Methods49['put']['resBody'], BasicHeaders, Methods49['put']['status']>(prefix, prefix2, PUT, option).json().then(r => r.body),
            /**
             * シャドーイングノートを削除する。
             * @returns 成功
             */
            delete: (option?: { config?: T }) =>
              fetch<Methods49['delete']['resBody'], BasicHeaders, Methods49['delete']['status']>(prefix, prefix2, DELETE, option).json(),
            /**
             * シャドーイングノートを削除する。
             * @returns 成功
             */
            $delete: (option?: { config?: T }) =>
              fetch<Methods49['delete']['resBody'], BasicHeaders, Methods49['delete']['status']>(prefix, prefix2, DELETE, option).json().then(r => r.body),
            $path: () => `${prefix}${prefix2}`
          }
        }
      },
      shadowing_recordings: {
        _student_shadowing_recording_id: (val2: number) => {
          const prefix2 = `${PATH47}/${val2}`

          return {
            file: {
              /**
               * シャドーイング録音音声ファイルを取得する。
               *
               * Acceptヘッダーはapplication/octet-streamを指定しなければならないことに注意する。
               * @returns 成功
               */
              get: (option?: { config?: T }) =>
                fetch<Methods50['get']['resBody'], BasicHeaders, Methods50['get']['status']>(prefix, `${prefix2}${PATH48}`, GET, option).blob(),
              /**
               * シャドーイング録音音声ファイルを取得する。
               *
               * Acceptヘッダーはapplication/octet-streamを指定しなければならないことに注意する。
               * @returns 成功
               */
              $get: (option?: { config?: T }) =>
                fetch<Methods50['get']['resBody'], BasicHeaders, Methods50['get']['status']>(prefix, `${prefix2}${PATH48}`, GET, option).blob().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH48}`
            }
          }
        }
      },
      students: {
        _progrit_student_id: (val2: number) => {
          const prefix2 = `${PATH49}/${val2}`

          return {
            shadowing_corrections: {
              /**
               * シャドーイング添削一覧を取得する。
               *
               * - created_atの降順 (新着順) にソートして返す
               * - 1ページの件数は50件
               *
               * 音声一覧の仕様詳細は下記参照。
               * - [Product/Gon(学習アプリ)/シャドーイング添削FB反映機能](https://progrit-dev.esa.io/posts/134#3.%20%E9%9F%B3%E5%A3%B0%E4%B8%80%E8%A6%A7%E7%94%BB%E9%9D%A2)
               * @returns 成功
               */
              get: (option?: { query?: Methods51['get']['query'], config?: T }) =>
                fetch<Methods51['get']['resBody'], BasicHeaders, Methods51['get']['status']>(prefix, `${prefix2}${PATH50}`, GET, option).json(),
              /**
               * シャドーイング添削一覧を取得する。
               *
               * - created_atの降順 (新着順) にソートして返す
               * - 1ページの件数は50件
               *
               * 音声一覧の仕様詳細は下記参照。
               * - [Product/Gon(学習アプリ)/シャドーイング添削FB反映機能](https://progrit-dev.esa.io/posts/134#3.%20%E9%9F%B3%E5%A3%B0%E4%B8%80%E8%A6%A7%E7%94%BB%E9%9D%A2)
               * @returns 成功
               */
              $get: (option?: { query?: Methods51['get']['query'], config?: T }) =>
                fetch<Methods51['get']['resBody'], BasicHeaders, Methods51['get']['status']>(prefix, `${prefix2}${PATH50}`, GET, option).json().then(r => r.body),
              $path: (option?: { method?: 'get'; query: Methods51['get']['query'] }) =>
                `${prefix}${prefix2}${PATH50}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
            },
            shadowing_notes: {
              /**
               * シャドーイングノート一覧を取得する。
               *
               * - updated_atの降順 (更新順) にソートして返す
               *
               * 音声一覧の仕様詳細は下記参照。
               * - [Product/Gon(学習アプリ)/シャドーイング添削FB反映機能](https://progrit-dev.esa.io/posts/134#%E3%83%8E%E3%83%BC%E3%83%88)
               * @returns 成功
               */
              get: (option?: { config?: T }) =>
                fetch<Methods52['get']['resBody'], BasicHeaders, Methods52['get']['status']>(prefix, `${prefix2}${PATH51}`, GET, option).json(),
              /**
               * シャドーイングノート一覧を取得する。
               *
               * - updated_atの降順 (更新順) にソートして返す
               *
               * 音声一覧の仕様詳細は下記参照。
               * - [Product/Gon(学習アプリ)/シャドーイング添削FB反映機能](https://progrit-dev.esa.io/posts/134#%E3%83%8E%E3%83%BC%E3%83%88)
               * @returns 成功
               */
              $get: (option?: { config?: T }) =>
                fetch<Methods52['get']['resBody'], BasicHeaders, Methods52['get']['status']>(prefix, `${prefix2}${PATH51}`, GET, option).json().then(r => r.body),
              /**
               * シャドーイングノートを登録する。
               * @returns 成功
               */
              post: (option: { body: Methods52['post']['reqBody'], config?: T }) =>
                fetch<Methods52['post']['resBody'], BasicHeaders, Methods52['post']['status']>(prefix, `${prefix2}${PATH51}`, POST, option).json(),
              /**
               * シャドーイングノートを登録する。
               * @returns 成功
               */
              $post: (option: { body: Methods52['post']['reqBody'], config?: T }) =>
                fetch<Methods52['post']['resBody'], BasicHeaders, Methods52['post']['status']>(prefix, `${prefix2}${PATH51}`, POST, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH51}`
            }
          }
        },
        _student_id: (val2: string) => {
          const prefix2 = `${PATH49}/${val2}`

          return {
            schedules: {
              _schedule_id: (val4: number) => {
                const prefix4 = `${prefix2}${PATH30}/${val4}`

                return {
                  entire_schedule: {
                    /**
                     * 指定されたスケジュールの、全体スケジュール取得を取得する。
                     * @returns 成功
                     */
                    get: (option?: { config?: T }) =>
                      fetch<Methods53['get']['resBody'], BasicHeaders, Methods53['get']['status']>(prefix, `${prefix4}${PATH52}`, GET, option).json(),
                    /**
                     * 指定されたスケジュールの、全体スケジュール取得を取得する。
                     * @returns 成功
                     */
                    $get: (option?: { config?: T }) =>
                      fetch<Methods53['get']['resBody'], BasicHeaders, Methods53['get']['status']>(prefix, `${prefix4}${PATH52}`, GET, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix4}${PATH52}`
                  },
                  study_menus: {
                    _schedule_study_menu_id: (val6: number) => {
                      const prefix6 = `${prefix4}${PATH53}/${val6}`

                      return {
                        /**
                         * 指定されたスケジュールのスケジュール学習メニュー設定を取得する。
                         * @returns 成功
                         */
                        get: (option?: { config?: T }) =>
                          fetch<Methods55['get']['resBody'], BasicHeaders, Methods55['get']['status']>(prefix, prefix6, GET, option).json(),
                        /**
                         * 指定されたスケジュールのスケジュール学習メニュー設定を取得する。
                         * @returns 成功
                         */
                        $get: (option?: { config?: T }) =>
                          fetch<Methods55['get']['resBody'], BasicHeaders, Methods55['get']['status']>(prefix, prefix6, GET, option).json().then(r => r.body),
                        /**
                         * スケジュール学習メニュー設定(5ステップのメニュー)を削除する。
                         *
                         * 指定されたスケジュール学習メニュー設定にトレーニングが登録されている場合、トレーニングを削除する。
                         *
                         * ### 削除可能条件
                         * - 指定されたスケジュール学習メニュー設定にトレーニングが登録されている場合、トレーニングに紐づく学習予定、学習記録が存在しないこと
                         * - 上記条件を満たさない場合、422を返す
                         * @returns 成功
                         */
                        delete: (option?: { config?: T }) =>
                          fetch<Methods55['delete']['resBody'], BasicHeaders, Methods55['delete']['status']>(prefix, prefix6, DELETE, option).json(),
                        /**
                         * スケジュール学習メニュー設定(5ステップのメニュー)を削除する。
                         *
                         * 指定されたスケジュール学習メニュー設定にトレーニングが登録されている場合、トレーニングを削除する。
                         *
                         * ### 削除可能条件
                         * - 指定されたスケジュール学習メニュー設定にトレーニングが登録されている場合、トレーニングに紐づく学習予定、学習記録が存在しないこと
                         * - 上記条件を満たさない場合、422を返す
                         * @returns 成功
                         */
                        $delete: (option?: { config?: T }) =>
                          fetch<Methods55['delete']['resBody'], BasicHeaders, Methods55['delete']['status']>(prefix, prefix6, DELETE, option).json().then(r => r.body),
                        $path: () => `${prefix}${prefix6}`
                      }
                    },
                    /**
                     * 指定されたスケジュールのスケジュール学習メニュー設定一覧を取得する。
                     * @returns 成功
                     */
                    get: (option?: { config?: T }) =>
                      fetch<Methods54['get']['resBody'], BasicHeaders, Methods54['get']['status']>(prefix, `${prefix4}${PATH53}`, GET, option).json(),
                    /**
                     * 指定されたスケジュールのスケジュール学習メニュー設定一覧を取得する。
                     * @returns 成功
                     */
                    $get: (option?: { config?: T }) =>
                      fetch<Methods54['get']['resBody'], BasicHeaders, Methods54['get']['status']>(prefix, `${prefix4}${PATH53}`, GET, option).json().then(r => r.body),
                    /**
                     * スケジュール学習メニュー設定を登録する。
                     * @returns 成功
                     */
                    post: (option: { body: Methods54['post']['reqBody'], config?: T }) =>
                      fetch<Methods54['post']['resBody'], BasicHeaders, Methods54['post']['status']>(prefix, `${prefix4}${PATH53}`, POST, option).json(),
                    /**
                     * スケジュール学習メニュー設定を登録する。
                     * @returns 成功
                     */
                    $post: (option: { body: Methods54['post']['reqBody'], config?: T }) =>
                      fetch<Methods54['post']['resBody'], BasicHeaders, Methods54['post']['status']>(prefix, `${prefix4}${PATH53}`, POST, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix4}${PATH53}`
                  },
                  weekly_interviews: {
                    /**
                     * 面談情報を週べて取得する
                     * @returns 成功
                     */
                    get: (option?: { config?: T }) =>
                      fetch<Methods56['get']['resBody'], BasicHeaders, Methods56['get']['status']>(prefix, `${prefix4}${PATH54}`, GET, option).json(),
                    /**
                     * 面談情報を週べて取得する
                     * @returns 成功
                     */
                    $get: (option?: { config?: T }) =>
                      fetch<Methods56['get']['resBody'], BasicHeaders, Methods56['get']['status']>(prefix, `${prefix4}${PATH54}`, GET, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix4}${PATH54}`
                  },
                  weekly_study_status: {
                    /**
                     * ScheduleIdに紐づく学習状況をweeklyですべて取得する
                     * 週単位で集計された情報を返却する
                     * @returns 成功
                     */
                    get: (option?: { config?: T }) =>
                      fetch<Methods57['get']['resBody'], BasicHeaders, Methods57['get']['status']>(prefix, `${prefix4}${PATH55}`, GET, option).json(),
                    /**
                     * ScheduleIdに紐づく学習状況をweeklyですべて取得する
                     * 週単位で集計された情報を返却する
                     * @returns 成功
                     */
                    $get: (option?: { config?: T }) =>
                      fetch<Methods57['get']['resBody'], BasicHeaders, Methods57['get']['status']>(prefix, `${prefix4}${PATH55}`, GET, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix4}${PATH55}`
                  },
                  weeks: {
                    _week: (val6: number) => {
                      const prefix6 = `${prefix4}${PATH36}/${val6}`

                      return {
                        interview: {
                          /**
                           * 週間面談詳細を取得する
                           * @returns 成功
                           */
                          get: (option?: { config?: T }) =>
                            fetch<Methods60['get']['resBody'], BasicHeaders, Methods60['get']['status']>(prefix, `${prefix6}${PATH56}`, GET, option).json(),
                          /**
                           * 週間面談詳細を取得する
                           * @returns 成功
                           */
                          $get: (option?: { config?: T }) =>
                            fetch<Methods60['get']['resBody'], BasicHeaders, Methods60['get']['status']>(prefix, `${prefix6}${PATH56}`, GET, option).json().then(r => r.body),
                          /**
                           * 週間面談詳細を作成・または更新を行う。
                           *
                           * 面談情報は1つしか登録できないため、作成と更新の両方をこのメソッドで処理する(POST)
                           * @returns 新たにリソースを作成した場合
                           */
                          post: (option: { body: Methods60['post']['reqBody'], config?: T }) =>
                            fetch<Methods60['post']['resBody'], BasicHeaders, Methods60['post']['status']>(prefix, `${prefix6}${PATH56}`, POST, option).json(),
                          /**
                           * 週間面談詳細を作成・または更新を行う。
                           *
                           * 面談情報は1つしか登録できないため、作成と更新の両方をこのメソッドで処理する(POST)
                           * @returns 新たにリソースを作成した場合
                           */
                          $post: (option: { body: Methods60['post']['reqBody'], config?: T }) =>
                            fetch<Methods60['post']['resBody'], BasicHeaders, Methods60['post']['status']>(prefix, `${prefix6}${PATH56}`, POST, option).json().then(r => r.body),
                          $path: () => `${prefix}${prefix6}${PATH56}`
                        },
                        study_status: {
                          /**
                           * 指定した週番号の学習状況を取得する。
                           * 日単位で集計された情報を返却する
                           * @returns 成功
                           */
                          get: (option?: { config?: T }) =>
                            fetch<Methods61['get']['resBody'], BasicHeaders, Methods61['get']['status']>(prefix, `${prefix6}${PATH57}`, GET, option).json(),
                          /**
                           * 指定した週番号の学習状況を取得する。
                           * 日単位で集計された情報を返却する
                           * @returns 成功
                           */
                          $get: (option?: { config?: T }) =>
                            fetch<Methods61['get']['resBody'], BasicHeaders, Methods61['get']['status']>(prefix, `${prefix6}${PATH57}`, GET, option).json().then(r => r.body),
                          $path: () => `${prefix}${prefix6}${PATH57}`
                        },
                        /**
                         * 指定されたスケジュールのスケジュール週設定を取得する。
                         * @returns 成功
                         */
                        get: (option?: { config?: T }) =>
                          fetch<Methods59['get']['resBody'], BasicHeaders, Methods59['get']['status']>(prefix, prefix6, GET, option).json(),
                        /**
                         * 指定されたスケジュールのスケジュール週設定を取得する。
                         * @returns 成功
                         */
                        $get: (option?: { config?: T }) =>
                          fetch<Methods59['get']['resBody'], BasicHeaders, Methods59['get']['status']>(prefix, prefix6, GET, option).json().then(r => r.body),
                        /**
                         * スケジュール週設定を削除する。
                         *
                         * ### 削除可能条件
                         * - 最終週かつ最終週にトレーニングが登録されていないこと
                         * - 上記条件を満たさない場合、422を返す
                         * @returns 成功
                         */
                        delete: (option?: { config?: T }) =>
                          fetch<Methods59['delete']['resBody'], BasicHeaders, Methods59['delete']['status']>(prefix, prefix6, DELETE, option).json(),
                        /**
                         * スケジュール週設定を削除する。
                         *
                         * ### 削除可能条件
                         * - 最終週かつ最終週にトレーニングが登録されていないこと
                         * - 上記条件を満たさない場合、422を返す
                         * @returns 成功
                         */
                        $delete: (option?: { config?: T }) =>
                          fetch<Methods59['delete']['resBody'], BasicHeaders, Methods59['delete']['status']>(prefix, prefix6, DELETE, option).json().then(r => r.body),
                        $path: () => `${prefix}${prefix6}`
                      }
                    },
                    /**
                     * 指定されたスケジュールのスケジュール週設定一覧を取得する。
                     * @returns 成功
                     */
                    get: (option?: { config?: T }) =>
                      fetch<Methods58['get']['resBody'], BasicHeaders, Methods58['get']['status']>(prefix, `${prefix4}${PATH36}`, GET, option).json(),
                    /**
                     * 指定されたスケジュールのスケジュール週設定一覧を取得する。
                     * @returns 成功
                     */
                    $get: (option?: { config?: T }) =>
                      fetch<Methods58['get']['resBody'], BasicHeaders, Methods58['get']['status']>(prefix, `${prefix4}${PATH36}`, GET, option).json().then(r => r.body),
                    /**
                     * スケジュール週設定を登録する
                     * @returns 成功
                     */
                    post: (option?: { config?: T }) =>
                      fetch<Methods58['post']['resBody'], BasicHeaders, Methods58['post']['status']>(prefix, `${prefix4}${PATH36}`, POST, option).json(),
                    /**
                     * スケジュール週設定を登録する
                     * @returns 成功
                     */
                    $post: (option?: { config?: T }) =>
                      fetch<Methods58['post']['resBody'], BasicHeaders, Methods58['post']['status']>(prefix, `${prefix4}${PATH36}`, POST, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix4}${PATH36}`
                  },
                  wpm_transitions: {
                    /**
                     * 学習記録(速読)のWPM推移一覧を取得する。
                     *
                     * グラフ表示は最大2回目までの仕様のため、study_countが3以上のデータが存在してもレスポンスでは返さない。
                     *
                     * レスポンスの参照フィールドは下記URLを参照。
                     *
                     * <img src="./images/wpm_transition.png">
                     * @returns 成功
                     */
                    get: (option?: { config?: T }) =>
                      fetch<Methods62['get']['resBody'], BasicHeaders, Methods62['get']['status']>(prefix, `${prefix4}${PATH58}`, GET, option).json(),
                    /**
                     * 学習記録(速読)のWPM推移一覧を取得する。
                     *
                     * グラフ表示は最大2回目までの仕様のため、study_countが3以上のデータが存在してもレスポンスでは返さない。
                     *
                     * レスポンスの参照フィールドは下記URLを参照。
                     *
                     * <img src="./images/wpm_transition.png">
                     * @returns 成功
                     */
                    $get: (option?: { config?: T }) =>
                      fetch<Methods62['get']['resBody'], BasicHeaders, Methods62['get']['status']>(prefix, `${prefix4}${PATH58}`, GET, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix4}${PATH58}`
                  }
                }
              }
            },
            student_courses: {
              _student_course_id: (val4: string) => {
                const prefix4 = `${prefix2}${PATH59}/${val4}`

                return {
                  versant_exam_records: {
                    _versant_exam_record_id: (val6: number) => {
                      const prefix6 = `${prefix4}${PATH33}/${val6}`

                      return {
                        /**
                         * VERSANTテスト記録を更新する。
                         * @returns 成功
                         */
                        put: (option: { body: Methods64['put']['reqBody'], config?: T }) =>
                          fetch<Methods64['put']['resBody'], BasicHeaders, Methods64['put']['status']>(prefix, prefix6, PUT, option).json(),
                        /**
                         * VERSANTテスト記録を更新する。
                         * @returns 成功
                         */
                        $put: (option: { body: Methods64['put']['reqBody'], config?: T }) =>
                          fetch<Methods64['put']['resBody'], BasicHeaders, Methods64['put']['status']>(prefix, prefix6, PUT, option).json().then(r => r.body),
                        /**
                         * VERSANTテスト記録を削除する。
                         * @returns 成功
                         */
                        delete: (option?: { config?: T }) =>
                          fetch<Methods64['delete']['resBody'], BasicHeaders, Methods64['delete']['status']>(prefix, prefix6, DELETE, option).json(),
                        /**
                         * VERSANTテスト記録を削除する。
                         * @returns 成功
                         */
                        $delete: (option?: { config?: T }) =>
                          fetch<Methods64['delete']['resBody'], BasicHeaders, Methods64['delete']['status']>(prefix, prefix6, DELETE, option).json().then(r => r.body),
                        $path: () => `${prefix}${prefix6}`
                      }
                    },
                    /**
                     * VERSANTテスト記録一覧を取得する。
                     * @returns 成功
                     */
                    get: (option?: { config?: T }) =>
                      fetch<Methods63['get']['resBody'], BasicHeaders, Methods63['get']['status']>(prefix, `${prefix4}${PATH33}`, GET, option).json(),
                    /**
                     * VERSANTテスト記録一覧を取得する。
                     * @returns 成功
                     */
                    $get: (option?: { config?: T }) =>
                      fetch<Methods63['get']['resBody'], BasicHeaders, Methods63['get']['status']>(prefix, `${prefix4}${PATH33}`, GET, option).json().then(r => r.body),
                    /**
                     * VERSANTテスト記録を登録する。
                     * @returns 成功
                     */
                    post: (option: { body: Methods63['post']['reqBody'], config?: T }) =>
                      fetch<Methods63['post']['resBody'], BasicHeaders, Methods63['post']['status']>(prefix, `${prefix4}${PATH33}`, POST, option).json(),
                    /**
                     * VERSANTテスト記録を登録する。
                     * @returns 成功
                     */
                    $post: (option: { body: Methods63['post']['reqBody'], config?: T }) =>
                      fetch<Methods63['post']['resBody'], BasicHeaders, Methods63['post']['status']>(prefix, `${prefix4}${PATH33}`, POST, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix4}${PATH33}`
                  }
                }
              }
            }
          }
        }
      }
    },
    v3: {
      data: {
        consultants: {
          sync: {
            /**
             * データを同期するAPI
             *
             * [スプレットシート](https://docs.google.com/spreadsheets/d/16bxz9iwftTKD3eFdqrzUQNxAqS1893iMqKNRkA8uz8Q/edit#gid=1885331552)
             * @returns 成功
             */
            post: (option: { body: Methods65['post']['reqBody'], config?: T }) =>
              fetch<Methods65['post']['resBody'], BasicHeaders, Methods65['post']['status']>(prefix, PATH60, POST, option).json(),
            /**
             * データを同期するAPI
             *
             * [スプレットシート](https://docs.google.com/spreadsheets/d/16bxz9iwftTKD3eFdqrzUQNxAqS1893iMqKNRkA8uz8Q/edit#gid=1885331552)
             * @returns 成功
             */
            $post: (option: { body: Methods65['post']['reqBody'], config?: T }) =>
              fetch<Methods65['post']['resBody'], BasicHeaders, Methods65['post']['status']>(prefix, PATH60, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${PATH60}`
          }
        }
      },
      schedules: {
        _schedule_id: (val2: number) => {
          const prefix2 = `${PATH61}/${val2}`

          return {
            conversation_study_records: {
              /**
               * オンライン英会話 学習記録一覧取得
               * @returns 成功
               */
              get: (option?: { config?: T }) =>
                fetch<Methods66['get']['resBody'], BasicHeaders, Methods66['get']['status']>(prefix, `${prefix2}${PATH62}`, GET, option).json(),
              /**
               * オンライン英会話 学習記録一覧取得
               * @returns 成功
               */
              $get: (option?: { config?: T }) =>
                fetch<Methods66['get']['resBody'], BasicHeaders, Methods66['get']['status']>(prefix, `${prefix2}${PATH62}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH62}`
            },
            entire_schedule: {
              /**
               * 指定されたスケジュールの、全体スケジュール取得を取得する。
               * @returns 成功
               */
              get: (option?: { config?: T }) =>
                fetch<Methods67['get']['resBody'], BasicHeaders, Methods67['get']['status']>(prefix, `${prefix2}${PATH52}`, GET, option).json(),
              /**
               * 指定されたスケジュールの、全体スケジュール取得を取得する。
               * @returns 成功
               */
              $get: (option?: { config?: T }) =>
                fetch<Methods67['get']['resBody'], BasicHeaders, Methods67['get']['status']>(prefix, `${prefix2}${PATH52}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH52}`
            },
            exam_study_records: {
              /**
               * 模試 学習記録一覧取得
               * @returns 成功
               */
              get: (option?: { config?: T }) =>
                fetch<Methods68['get']['resBody'], BasicHeaders, Methods68['get']['status']>(prefix, `${prefix2}${PATH63}`, GET, option).json(),
              /**
               * 模試 学習記録一覧取得
               * @returns 成功
               */
              $get: (option?: { config?: T }) =>
                fetch<Methods68['get']['resBody'], BasicHeaders, Methods68['get']['status']>(prefix, `${prefix2}${PATH63}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH63}`
            },
            extensive_reading_study_records: {
              /**
               * 多読 学習記録一覧取得
               * @returns 成功
               */
              get: (option?: { config?: T }) =>
                fetch<Methods69['get']['resBody'], BasicHeaders, Methods69['get']['status']>(prefix, `${prefix2}${PATH64}`, GET, option).json(),
              /**
               * 多読 学習記録一覧取得
               * @returns 成功
               */
              $get: (option?: { config?: T }) =>
                fetch<Methods69['get']['resBody'], BasicHeaders, Methods69['get']['status']>(prefix, `${prefix2}${PATH64}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH64}`
            },
            grammar_study_records: {
              /**
               * 文法 学習記録一覧取得
               * @returns 成功
               */
              get: (option?: { config?: T }) =>
                fetch<Methods70['get']['resBody'], BasicHeaders, Methods70['get']['status']>(prefix, `${prefix2}${PATH65}`, GET, option).json(),
              /**
               * 文法 学習記録一覧取得
               * @returns 成功
               */
              $get: (option?: { config?: T }) =>
                fetch<Methods70['get']['resBody'], BasicHeaders, Methods70['get']['status']>(prefix, `${prefix2}${PATH65}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH65}`
            },
            intensive_reading_study_records: {
              /**
               * 精読 学習記録一覧取得
               * @returns 成功
               */
              get: (option?: { config?: T }) =>
                fetch<Methods71['get']['resBody'], BasicHeaders, Methods71['get']['status']>(prefix, `${prefix2}${PATH66}`, GET, option).json(),
              /**
               * 精読 学習記録一覧取得
               * @returns 成功
               */
              $get: (option?: { config?: T }) =>
                fetch<Methods71['get']['resBody'], BasicHeaders, Methods71['get']['status']>(prefix, `${prefix2}${PATH66}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH66}`
            },
            speed_reading_study_records: {
              /**
               * 速読 学習記録一覧取得
               * @returns 成功
               */
              get: (option?: { config?: T }) =>
                fetch<Methods72['get']['resBody'], BasicHeaders, Methods72['get']['status']>(prefix, `${prefix2}${PATH67}`, GET, option).json(),
              /**
               * 速読 学習記録一覧取得
               * @returns 成功
               */
              $get: (option?: { config?: T }) =>
                fetch<Methods72['get']['resBody'], BasicHeaders, Methods72['get']['status']>(prefix, `${prefix2}${PATH67}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH67}`
            }
          }
        }
      },
      trainings: {
        _training_id: (val2: number) => {
          const prefix2 = `${PATH68}/${val2}`

          return {
            has_plan: {
              /**
               * トレーニングの指定期間内に学習プランの存在をチェックします
               * @returns 成功
               */
              get: (option: { query: Methods73['get']['query'], config?: T }) =>
                fetch<Methods73['get']['resBody'], BasicHeaders, Methods73['get']['status']>(prefix, `${prefix2}${PATH69}`, GET, option).json(),
              /**
               * トレーニングの指定期間内に学習プランの存在をチェックします
               * @returns 成功
               */
              $get: (option: { query: Methods73['get']['query'], config?: T }) =>
                fetch<Methods73['get']['resBody'], BasicHeaders, Methods73['get']['status']>(prefix, `${prefix2}${PATH69}`, GET, option).json().then(r => r.body),
              $path: (option?: { method?: 'get'; query: Methods73['get']['query'] }) =>
                `${prefix}${prefix2}${PATH69}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
            }
          }
        }
      }
    },
    v4: {
      schedules: {
        _schedule_id: (val2: number) => {
          const prefix2 = `${PATH70}/${val2}`

          return {
            extensive_reading_study_records: {
              /**
               * 多読 学習記録一覧取得
               * （多読教材単位で読了したものを集約した一覧）
               * @returns 成功
               */
              get: (option?: { config?: T }) =>
                fetch<Methods74['get']['resBody'], BasicHeaders, Methods74['get']['status']>(prefix, `${prefix2}${PATH64}`, GET, option).json(),
              /**
               * 多読 学習記録一覧取得
               * （多読教材単位で読了したものを集約した一覧）
               * @returns 成功
               */
              $get: (option?: { config?: T }) =>
                fetch<Methods74['get']['resBody'], BasicHeaders, Methods74['get']['status']>(prefix, `${prefix2}${PATH64}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH64}`
            }
          }
        }
      }
    }
  }
}

// prettier-ignore
export type ApiInstance = ReturnType<typeof api>
// prettier-ignore
export default api
