import { mutateGlobal, useGlobalState } from '~/hooks/useGlobalState';

const LOADING_SWR_KEY = 'loading';

export const mutateLoadingCount = (updateFunc: (count: number) => number) =>
  mutateGlobal(LOADING_SWR_KEY, (count?: number) => updateFunc(count ?? 0));

export const useLoading = () => {
  const { data } = useGlobalState(LOADING_SWR_KEY, 0);
  return (data ?? 0) > 0;
};
