import type { captureException } from '@sentry/nextjs';
import type { AxiosError } from 'axios';
import type { JwtPayload } from 'jwt-decode';
import jwtDecode from 'jwt-decode';

import { rules } from './rules';

export interface Rule {
  path: RegExp;
}

const findRuleFromError = (rules: Rule[], error: AxiosError) => {
  const { config } = error;
  const matchRule = rules.find((rule) => {
    return rule.path.test(config.url ?? '');
  });
  return matchRule;
};

const getTokenInfo = (token: string) => {
  try {
    const { exp, iat } = jwtDecode<JwtPayload>(token);

    // NOTE: JWTトークンに格納されるタイムスタンプは秒なのでミリ秒になおす
    const issuedAt = iat != null ? new Date(iat * 1000).toISOString() : null;
    const expiredAt = exp != null ? new Date(exp * 1000).toISOString() : null;

    return { issuedAt, expiredAt };
  } catch {
    return null;
  }
};

export const captureFactory = (
  error: AxiosError
): Parameters<typeof captureException> => {
  const { config, response } = error;
  const rule = findRuleFromError(rules, error);
  return [
    new Error(
      `${config.method?.toUpperCase() ?? 'unknown'} ${
        config.url ?? 'unknown'
      } ${response?.status ?? 'unknown'}(${response?.statusText})`
    ),
    (scope) => {
      if (response == null) return scope;
      scope.setExtras({
        body: config.data,
        params: config.params,
        responseData: response.data,
      });

      if (
        response.status === 401 &&
        typeof config.headers?.Authorization === 'string'
      ) {
        const token = config.headers.Authorization.replace('Bearer ', '');
        scope.setExtra('tokenInfo', getTokenInfo(token));
      }
      scope.setFingerprint([
        `{{${config.method ?? 'unknown'}-${
          rule ? rule.path.toString() : config.url
        }-${response.status}}}`,
      ]);
      return scope;
    },
  ];
};
