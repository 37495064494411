import type { ReactNode } from 'react';

import { useAuthMiddleware } from '~/hooks/useAuth';
import ErrorPage from '~/pages/_error';

interface AuthMiddlewareProps {
  children: ReactNode;
}

export const AuthMiddleware = ({ children }: AuthMiddlewareProps) => {
  const { isValidating, statusError } = useAuthMiddleware();

  if (statusError) return <ErrorPage err={statusError} />;

  if (isValidating) return null;

  return <>{children}</>;
};
