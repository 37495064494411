import {
  createTheme,
  unstable_createMuiStrictModeTheme,
} from '@mui/material/styles';

import tw from 'twin.macro';

import tailwindTheme from '../../../tailwind.config';

const {
  extend: { colors },
  fontSize,
} = tailwindTheme.theme;

// NOTE: `Warning: findDOMNode is deprecated in StrictMode` が出る問題への対策
// https://stackoverflow.com/a/64135466
const createThemeFunction =
  process.env.ENV === 'local' ? unstable_createMuiStrictModeTheme : createTheme;

export const theme = createThemeFunction({
  palette: {
    text: { primary: colors.gray['110'] },
    primary: {
      main: colors.orange['50'],
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#FFFFFF',
      contrastText: colors.orange['50'],
    },
    action: {
      hover: colors.gray['30'],
    },
    divider: colors.gray['40'],
    error: { main: colors.error },
    default: {
      main: colors.gray['110'],
    },
  },
  typography: {
    h1: {
      fontSize: fontSize['2xl'][0],
      lineHeight: fontSize['2xl'][1],
      fontWeight: 'bold',
    },
    h2: {
      fontSize: fontSize.xl[0],
      lineHeight: fontSize.xl[1],
      fontWeight: 'bold',
    },
    h3: {
      fontSize: fontSize.base[0],
      lineHeight: fontSize.base[1],
      fontWeight: 'bold',
    },
    h4: {
      fontSize: fontSize.sm[0],
      lineHeight: fontSize.sm[1],
      fontWeight: 'bold',
    },
    body1: { fontSize: fontSize.base[0], lineHeight: fontSize.base[1] },
    body2: { fontSize: fontSize.base[0], lineHeight: fontSize.base[1] },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: 'var(--appbar-h)',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 14,
          // NOTE: https://github.com/mui/material-ui/issues/28691 対応
          '&.Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.08) !important',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': tw`text-gray-60 opacity-100`,
        },
        root: {
          '&.Mui-disabled': {
            backgroundColor: `${colors.gray['40']}`,
            border: `${colors.gray['60']}`,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        color: 'default',
      },
      styleOverrides: {
        root: {
          minWidth: 120,
          whiteSpace: 'nowrap',
          textTransform: 'none', // NOTE: アルファベットが大文字にならないように
        },
        containedPrimary: {
          backgroundColor: colors.orange['50'],
          '&:hover': {
            backgroundColor: colors.orange['40'],
          },
          '&:disabled': {
            backgroundColor: colors.brown['20'],
            color: '#ffffff',
          },
        },
        contained: {
          backgroundColor: colors.gray['40'],
        },
        outlinedSecondary: {
          color: colors.gray['110'],
          borderColor: colors.gray['40'],
          '&:hover': {
            backgroundColor: colors.gray['30'],
            borderColor: colors.gray['40'],
          },
          '&:disabled': {
            color: colors.gray['60'],
          },
        },
        sizeSmall: {
          height: 32,
        },
        sizeLarge: {
          width: 240,
          height: 48,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paperWidthSm: {
          maxWidth: 400,
        },
        paperWidthMd: {
          maxWidth: 700,
        },
        paperWidthLg: {
          maxWidth: 1000,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingTop: 32,
          paddingRight: 32,
          paddingLeft: 32,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingRight: 32,
          paddingLeft: 32,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingBottom: 32,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        hover: {
          '&:hover': {
            backgroundColor: `${colors.orange['20']} !important`, // NOTE: palette.action.hoverと競合しないようにimportantをつける
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: colors.gray['110'],
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSlider: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        colorPrimary: {
          '.MuiSlider-rail': {
            backgroundColor: colors.orange['20'],
            opacity: 1,
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          width: 60,
          flexShrink: 0,
        },
        paper: {
          width: 60,
          backgroundColor: colors.orange['50'],
        },
      },
    },
  },
  zIndex: {
    drawer: 20,
  },
});
