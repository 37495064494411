import type { AxiosError } from 'axios';
import axios from 'axios';
import sanitize from 'sanitize-html';

import type { ApiErrorResponse } from './types';

export const getSalesForceUrl = (studentId: string) => {
  return `https://eigo-tokkun.lightning.force.com/lightning/r/Account/${studentId}/view`;
};

export const sanitizeTextLink = (text: string) => {
  const replacedHtml = text.replaceAll(
    // NOTE: https://tools.ietf.org/html/rfc3986#page-50 の `pct-encoded`, `unreserved`, `reserved`にある文字列
    // 加えて句読点を除く日本語文字にマッチさせる ref: https://github.com/textlint-ja/textlint-rule-preset-JTF-style/issues/1
    /(https?:\/\/([%A-Za-z0-9-._~:/?#[\]@!$&'()*+,;=]|[\u3400-\u4DBF\u4E00-\u9FFF\uF900-\uFAFF]|[\uD840-\uD87F][\uDC00-\uDFFF]|[ぁ-んァ-ヶ])+)/g,
    '<a href="$1" target="_blank" rel="noopener noreferrer" class="text-orange-50">$1</a>'
  );
  return sanitize(replacedHtml, {
    allowedTags: ['a'],
    allowedAttributes: { a: ['href', 'target', 'rel', 'class'] },
    disallowedTagsMode: 'escape',
  });
};

export const blankToNull = (value?: string | null) => {
  if (isEmpty(value)) return null;
  return value;
};

export const isEmpty = (
  value?: string | unknown[] | unknown | null
): value is
  | null
  | undefined
  | ''
  | []
  // eslint-disable-next-line @typescript-eslint/ban-types
  | {}
  | { [key: string]: undefined } => {
  return (
    value == null ||
    value === '' ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === 'object' &&
      (Object.keys(value as object).length === 0 ||
        Object.values(value as object).every((value) => value === undefined)))
  );
};

export const isAesopApiError = (
  error: unknown
): error is AxiosError<ApiErrorResponse> => {
  return isAxiosErrorExactly(error) && !isEmpty(error.response?.data);
};

export const isAxiosErrorExactly = (error: unknown): error is AxiosError => {
  // eslint-disable-next-line no-restricted-properties
  return axios.isAxiosError(error) && !axios.isCancel(error);
};
