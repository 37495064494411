import { Backdrop } from '@mui/material';
import { styled } from '@mui/material/styles';

import tw from 'twin.macro';

import { useLoading } from '~/hooks/useLoading';

const Piece = styled('div')({
  '&': tw`inline-block w-[6px] h-full bg-gray-110 ml-1`,
  '@keyframes stretch': {
    '0%, 40%, 100%': { transform: 'scaleY(0.4)' },
    '20%': { transform: 'scaleY(1)' },
  },
  animation: 'stretch 1.2s infinite ease-in-out',
  '&:nth-child(2)': { animationDelay: '-1.1s' },
  '&:nth-child(3)': { animationDelay: '-1s' },
  '&:nth-child(4)': { animationDelay: '-0.9s' },
  '&:nth-child(5)': { animationDelay: '-0.8s' },
});

export const Loading = () => {
  const isLoading = useLoading();

  return (
    <Backdrop
      className="bg-white/80 text-white"
      sx={{ zIndex: (theme) => theme.zIndex.tooltip + 1 }}
      open={isLoading}
      data-testid="loading"
      unmountOnExit // 非表示の場合はDOM上から消えるように
    >
      <div className="h-[40px] w-[50px] text-center text-[10px]">
        <Piece />
        <Piece />
        <Piece />
        <Piece />
        <Piece />
      </div>
    </Backdrop>
  );
};
