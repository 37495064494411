import type { NextPageContext } from 'next';

import { Error } from '~/components/Error';

type ErrorPageProps = Pick<NextPageContext, 'err'>;

const ErrorPage = (props: ErrorPageProps) => {
  return <Error title={props.err?.message} />;
};
ErrorPage.getInitialProps = async (ctx: NextPageContext) => {
  return ctx;
};

export default ErrorPage;
