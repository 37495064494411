import type { SWRResponse, MutatorCallback } from 'swr';
import useSWR, { mutate, useSWRConfig } from 'swr';

const genKey = (key: string) => `GLOBAL_${key}`;

export const mutateGlobal = <Data>(
  _key: string,
  data: Data | Promise<Data> | MutatorCallback<Data>
) => {
  const key = genKey(_key);
  return mutate<Data>(key, data, false);
};

export const useGlobalState = <Data = unknown, Error = unknown>(
  _key: string,
  initialData?: Data
): SWRResponse<Data, Error> => {
  const { cache } = useSWRConfig();
  const key = genKey(_key);

  if (cache.get(key) == null && initialData != null) {
    mutate(key, initialData, false);
  }

  return useSWR(key, null, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    fallbackData: initialData,
  });
};
