interface StatusErrorProps {
  message: string;
  statusCode: number;
  path?: string;
}
export class StatusError extends Error {
  path?: string;
  statusCode: number;
  constructor(props: StatusErrorProps) {
    super(props.message);
    this.path = props.path;
    this.statusCode = props.statusCode;
  }
}
